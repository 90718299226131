import { UserContextService } from './../../core/services/user-context.service';
import { UsersService } from 'src/app/core/services/users.service';
import { GlobalConstants } from './../../core/global-constants';
import { AfterViewInit, Component, Output, ViewChild } from '@angular/core';
import { MultiSelectDataType } from 'src/app/core/enums/MultiSelectDataType';
import { CustomSelectFilterComponent } from 'src/app/shared/custom/custom-select-filter/custom-select-filter.component';
import { FilterType } from 'src/app/core/enums/FilterType.enum';
import { TasksService } from 'src/app/core/services/tasks.service';
import { DealService } from 'src/app/core/services/deals.service';
import { ReplaySubject } from 'rxjs';
import { User } from 'src/app/core/models/UserModel';
import { CustomSelectFilterWithoutLazyComponent } from 'src/app/shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { RolesEnum } from 'src/app/core/enums/RolesEnum';

@Component({
  selector: 'pd-targeted-recall-task',
  templateUrl: './targeted-recall-task.component.html',
  styleUrls: ['./targeted-recall-task.component.scss']
})
export class TargetedRecallTaskComponent implements AfterViewInit {
  public selectDataTypes = MultiSelectDataType;
  @ViewChild("locationFilter") customSelectLocation: CustomSelectFilterComponent;
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;
  @Output() locationsLabel: string = 'Locations';

  public isSelectAllLocations: boolean;
  selectTextLocations: string;
  isSelectAllLocationsNumber: boolean;
  selectTextLocationsNumber: string;
  isSelectAllLocationsGender: boolean;
  selectTextLocationsGender: string;
  filterTypeLocation = FilterType.Location;

  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredUsers = [];
  private currentUser: User;
  private allFilteredUsers = [];
  public usersLabel: string = 'Users'
  public isSelectAnyUsersNotMasterLead: boolean = false;
  public avelableUsers: User[];

  public recallLocations = [];
  public tasksRoute = GlobalConstants.ROUTE_NAMES.Tasks;

  constructor(
    private taskService: TasksService,
    private usersService: UsersService,
    private dealService: DealService,
    private userContextService: UserContextService) { }

  async ngAfterViewInit(): Promise<void> {
    this.currentUser = this.userContextService.user.value;
    this.allFilteredUsers = await this.usersService.getUsers();
    this.setUserFilter();
    this.getUsersFilterData();
  }

  public submitRecall() {
    this.taskService.targetRecall(this.customSelectUser.dataArray.map((u) => u.id), this.recallLocations.map(a => a.id),
      this.isSelectAllLocations, this.selectTextLocations);
  }

  private setUserFilter() {
    this.avelableUsers = this.allFilteredUsers.filter(i => i.role_id !== RolesEnum.EA && i.role_id !== RolesEnum.EaCaller);

    if (this.currentUser.role_id == RolesEnum.Admin || this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
      if (this.currentUser.role_id == RolesEnum.EA || this.currentUser.role_id == RolesEnum.EaCaller) {
        this.avelableUsers = this.allFilteredUsers.filter(x => x.role_id == RolesEnum.Clothier || x.role_id == RolesEnum.Admin);
      }
      else {
        this.avelableUsers = this.avelableUsers.concat(this.allFilteredUsers.filter(x => x.role_id != RolesEnum.MasterLead));
      }
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != RolesEnum.Admin || x.role_id != RolesEnum.Clothier) {
          x.disabled = true
        }
      });
    }
    else {
      this.allFilteredUsers.forEach(x => {
        if (x.role_id != this.currentUser.role_id) {
          x.disabled = true
        }
      });
    }
    this.filteredUsers = this.allFilteredUsers.map(x => x);
  }

  getLocationsFilterData(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterData(FilterType.Location, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public async onSelectedItem(checked): Promise<void> {
    if (checked) {
      if (this.customSelectUser) {
        this.isSelectAnyUsersNotMasterLead = this.customSelectUser.dataArray.map((u) => u.id).length > 0;
      }
    }
    else {
      this.isSelectAnyUsersNotMasterLead = false;
    }
  }

  public onUserSelectedItem(checked) {
    if (this.customSelectUser.dataArray?.length == 0) {
      this.allFilteredUsers.forEach(x => {
        if (this.avelableUsers.some(u => u.id == x.id)) {
          x.disabled = false;
        }
      });
    }
    if (this.customSelectUser.dataArray?.length == 1) {
      if (this.customSelectUser.dataArray[0].role_id == RolesEnum.Clothier || this.customSelectUser.dataArray[0].role_id == RolesEnum.Admin) {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != RolesEnum.Clothier && x.role_id != RolesEnum.Admin)
            x.disabled = true;
        });
      }
      else {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id != this.customSelectUser.dataArray[0].role_id)
            x.disabled = true;
        });
      }
    }
    if (checked) {
      if (this.customSelectUser) {
        this.isSelectAnyUsersNotMasterLead = this.customSelectUser.dataArray.map((u) => u.id).length > 0;
      }
    }
    else {
      this.isSelectAnyUsersNotMasterLead = false;
    }
  }

  public getUsersFilterData() {
    this.filteredUsers = [];
    this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
    this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
    if (this.customSelectUser) {
      const savedFilter = JSON.parse(localStorage.getItem('filterUsers'));
      if (savedFilter && savedFilter.length > 0) {
        this.allFilteredUsers.forEach(x => {
          if (x.role_id == savedFilter[0].role_id) {
            x.disabled = false;
          } else {
            x.disabled = true;
          }
        });
      }
      this.customSelectUser.setData(this.filteredUsers);
      if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
        this.customSelectUser.label = savedFilter && savedFilter.length > 0 ?
          savedFilter.map(x => x.name).join(", ") : this.currentUser.name;
        this.customSelectUser.dataArray = savedFilter && savedFilter.length > 0 ?
          savedFilter : this.allFilteredUsers.filter(x => x.id == this.currentUser.id);
      }
    }
  }

  public getUsersAutocomplete(event) {
    let search = event.searchString;
    if (!search) {
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredUserMultiSelect.next(
      this.allFilteredUsers.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    this.filteredUsers = [];
    this.filteredUserMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (this.filteredUsers.length == 0) {
          this.filteredUsers.push(x[0]);
        } else {
          if (!this.filteredUsers.includes(x[i], 0)) {
            this.filteredUsers.push(x[i]);
          }
        }
      }
    });
    this.customSelectUser.setData(this.filteredUsers);
  }

  getLocationsAutocomplete(event) {
    let isNotleter: number = event.isNotLetter;
    this.dealService.getFilterDataAutocomplete(FilterType.Location, event.searchString, event.skip, event.take).then(response => {
      if (isNotleter == this.customSelectLocation.isNotLetter) {
        this.customSelectLocation.setData(response);
      }
    });
  }

  public getRecallLocations(locations) {
    this.recallLocations = locations.data;
    this.isSelectAllLocations = locations.isSelectAll;
    this.selectTextLocations = locations.selectText;
  }
}
