<div class="row wrapper">
  <div class="col-4 title">
    <span style="color: red; font-size: 20px">*</span>Role
  </div>
  <div class="col-8 work-field">
    @if (!roleProperty.role && !isEdit) {
    <pd-add-value (addValue)="addValue()"></pd-add-value>
    }
    @if ((isEdit || roleProperty.role)) {
    <div class="edit-role">
      <div class="select">
        <mat-form-field class="owner-select-global">
          <mat-select [disabled]="disabled" (selectionChange)="changeValue($event.value)" [(value)]="roleProperty.role">
            @for (role of roleList; track role) {
            <div>
              <mat-option [disabled]="isRoleDisabled(role)" [value]="role">{{role.name}}</mat-option>
            </div>
            }
          </mat-select>
        </mat-form-field>
      </div>
      @if (isAllowedFlags) {
      <div class="checkbox">
        <div class="row">

          @if (isAdmin) {
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isTasksTab">Tasks Tab</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isUsersTab">Users Tab</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAllowedExportLeadDate">Export Lead
            Date</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAllowedHistoryOfLeads">History Of
            Leads</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isRepoAccount">Repo Account</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isWhatsappChat">WhatsApp Chat</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isGlobalChat">Global Chat</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isAvatarEditor">Avatar Editor</mat-checkbox>
          <mat-checkbox class="col-12" [(ngModel)]="roleProperty.isSeeOthersCms">See other’s
            CMS</mat-checkbox>

          <div class="col-12">
            <mat-checkbox [(ngModel)]="roleProperty.isInSmReport">
              <span style="position: relative;">
                Include into SM report
                <div class="help-tip">
                  <p>In case you're providing Admin access to SM, please choose this option. This is needed to show the
                    results of these SMs in reports</p>
                </div>
              </span>
            </mat-checkbox>
          </div>
          }

          @if (isAdmin || isClothier) {
          <div class="col-12">
            <mat-checkbox [(ngModel)]="roleProperty.isNotInClothierReport">
              <span style="position: relative;">
                Don't include into daily clothier progress report
                <div class="help-tip">
                  <p>If this clothier/admin shouldn't be in the clothier progress daily report, please choose this
                    option</p>
                </div>
              </span>
            </mat-checkbox>
            @if(isClothier) {
            <mat-checkbox [(ngModel)]="roleProperty.isMarketLeader">
              <span style="position: relative;">
                Is Market Leader
              </span>
            </mat-checkbox>
            @if(roleProperty.isMarketLeader) {
            <div style="padding-top: 10px;"> 
              <pd-custom-select-without-lazy #userFilter (loadDataEvent)="getUsersFilterData()"
                (selectedItemEvent)="onSelectedItem($event)" (autoCompleteEvent)="getUsersAutocomplete($event)"
                dataLabel="Leader of" startLabel="Leader of" [isMultiple]="true">
              </pd-custom-select-without-lazy>
            </div>
            }
            }
          </div>
          }
        </div>
      </div>
      }
    </div>
    }
  </div>
  @if (isEaHasConnections) {
  <div>
    {{usersService.ClothierEaConnectionValidationMessage}}
  </div>
  }
</div>