import { Message } from "../models/chat-models/Message";

export class TwilioHelper {
  public static textForReactionMessage = '***Customer reacted with an emoji to the message***';

  public static isReactionMessage(message: Message) {
    return message.text === '' && message.isClientMessage && (!message.medias || message.medias.length === 0);
  }

  public static getMessageIcon(message: Message): string {
    switch (message.status) {
      case 'queued':
      case 'accepted':
        return 'fa-clock';
      case 'sent':
        return 'fa-paper-plane';
      case 'delivered':
      case 'received':
        return 'fa-check';
      case 'read':
        return 'fa-check-double';
      case 'failed':
      case 'undelivered':
        return 'fa-xmark';
      default:
        return '';
    }
  }

  public static getMessageIconClass(message: Message): string {
    return TwilioHelper.getMessageIcon(message);
  }
}
