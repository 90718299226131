import { ExportService } from './services/export.service';
import { AppRouterService } from './services/app-router.service';
import { HttpRestService } from './services/http-client/http-rest-client.service';
import { LoginService } from './services/login.service';
import { UserContextService } from './services/user-context.service';
import { ErrorHandlerService } from './services/http-client/error-handler.service';
import { InterceptorService } from './services/interseptor.service';
import { UsersService } from './services/users.service';
import { ClientCounterService } from './services/client-counter.service';
import { DealService } from './services/deals.service';
import { AuthGuard } from './guards/autuh.guard';
import { PaymentService } from './services/payment.service';
import { HotlistService } from './services/hotlist.service';
import { ImageService } from './services/image.service';
import { RoleService } from './services/role.service';
import { StageService } from './services/stage.service';
import { Modeler3dService } from './services/3d-modeler.service';
import { TasksService } from './services/tasks.service';
import { SignalrService } from './services/signalr.service';
import { UploadImageService } from './services/upload-image.service';
import { TwilioService } from './services/twilio.service';
import { AlertService } from './services/alert.service';
import { UserRequestService } from './services/user-request.service';
import { GeolacationService } from './services/geolocation.service';
import { UserStoreService } from './services/stores/user-store.service';
import { PipelineStoreService } from './services/stores/pipeline-store.service';
import { StageFeatureStoreService } from './services/stores/stage-feature-store.service';
import { NoteService } from './services/note.service';
import { PreviousRouteService } from './services/previousRoute.service';
import { PipelineDealsStoreService } from './services/stores/pipeline-deal-store.service';
import { AvatarBuilderService } from './services/avatar/avatar-builder.service';
import { Modeler3dProductService } from './services/3d-modeler-product.service';
import { AvatarFacadeService } from './services/avatar/avatar-facade.service';
import { AvatarEffectsService } from './services/avatar/avatar-effects.service.';
import { AvatarStoreService } from './services/avatar/avatar-store.service';
import { AvatarApiService } from './services/avatar/avatar-api.service';
import { AvatarCollectionApiService } from './services/avatar/avatar-collection-api.service';
import { Avatar2CollectionApiService } from './services/avatar/avatar-2-collection-api.service';
import { ConfirmService } from './services/confirm.service';
import { CmsService } from './services/cms.service';
import { AvatarDealFacadeService } from './services/avatar-deal/avatar-deal-facade.service';
import { AvatarDealApiService } from './services/avatar-deal/avatar-deal-api.service';
import { AvatarDealEffectsService } from './services/avatar-deal/avatar-deal-effects.service.';
import { AvatarDealStoreService } from './services/avatar-deal/avatar-deal-store.service';
import { ClientCardFacadeService } from './services/client-card/client-card-facade.service';
import { ClientCardEffectsService } from './services/client-card/client-card.service.';
import { ClientCardStoreService } from './services/client-card/client-card-store.service';
import { ClientCardApiService } from './services/client-card/client-card-api.service';
import { AchievementCardApiService } from './services/achievement-card/achievement-card-api.service';
import { AchievementCardStoreService } from './services/achievement-card/achievement-card-store.service';
import { AchievementCardFacadeService } from './services/achievement-card/achievement-card-facade.service';
import { AchievementCardEffectsService } from './services/achievement-card/achievement-card-effects.service';
import { SocialMediaAdsTokensService } from './services/social-media-ads-tokens.service';
import { BulkMessageRequestApiService } from './services/bulk-message/bulk-message-request-api.service';
import { BulkEmailRequestService } from './services/bulk-message/bulk-email-request.service';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MessageTemplateService } from './services/message-template.service';
import { BulkMessageSettingApiService } from './services/bulk-message/bulk-message-setting-api.service';
import { BulkMessageEffectsService } from './services/bulk-message/bulk-message-effects.service.';
import { BulkMessageFacadeService } from './services/bulk-message/bulk-message-facade.service';
import { BulkMessageStoreService } from './services/bulk-message/bulk-message-store.service';
import { ThirdPartyLinksService } from './services/third-party-links.service'

@NgModule({
    imports: [
        FormsModule
    ],
    declarations: [],
    providers: [
        AppRouterService,
        HttpRestService,
        LoginService,
        UserContextService,
        ErrorHandlerService,
        InterceptorService,
        UsersService,
        ClientCounterService,
        DealService,
        PaymentService,
        AuthGuard,
        HotlistService,
        ImageService,
        RoleService,
        StageService,
        Modeler3dService,
        TasksService,
        SignalrService,
        UploadImageService,
        TwilioService,
        AlertService,
        UserRequestService,
        GeolacationService,
        UserStoreService,
        PipelineStoreService,
        StageFeatureStoreService,
        NoteService,
        ExportService,
        PreviousRouteService,
        PipelineDealsStoreService,
        ConfirmService,
        AvatarBuilderService,
        Modeler3dProductService,
        AvatarFacadeService,
        AvatarEffectsService,
        AvatarStoreService,
        AvatarApiService,
        AvatarCollectionApiService,
        Avatar2CollectionApiService,
        PipelineDealsStoreService,
        CmsService,
        AvatarDealApiService,
        AvatarDealEffectsService,
        AvatarDealFacadeService,
        AvatarDealStoreService,
        BulkMessageEffectsService,
        BulkMessageFacadeService,
        BulkMessageStoreService,
        ClientCardEffectsService,
        ClientCardFacadeService,
        ClientCardStoreService,
        ClientCardApiService,
        AchievementCardEffectsService,
        AchievementCardFacadeService,
        AchievementCardStoreService,
        AchievementCardApiService,
        SocialMediaAdsTokensService,
        BulkMessageSettingApiService,
        BulkMessageRequestApiService,
        BulkEmailRequestService,
        MessageTemplateService,
        ThirdPartyLinksService,
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class CoreModule { }
