import { Injectable } from '@angular/core';
import { HttpRestService } from './http-client/http-rest-client.service';
import { BaseService } from './base.service';

@Injectable()
export class TwilioService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getAllHistory(): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/twilio/getAllHistory' }));
    }

    public async getAllCandidatesHistory(): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/twilio/getAllCandidatesHistory' }));
    }
    
    public async getHistory(dealId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/twilio/getHistory/' + dealId }));
    }

    public async getCandidatesHistory(dealId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({ url: '/twilio/getCandidatesHistory/' + dealId }));
    }

    public async getHistoryByNumber(phoneNumber: string): Promise<any> {
        return await this.handleResponse(this.restService.post(
            {
                url: '/twilio/getHistoryByNumber/',
                data: { toPhone: phoneNumber }
            }));
    }

    public async sendWhatsappMessage(toPhone: string, message, file): Promise<any> {
        let formData: FormData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }
        formData.append('toPhone', toPhone);
        formData.append('message', message);
        return await this.handleResponse(this.restService.upload(
            {
                url: '/twilio/sendWhatsappMessage',
                data: formData
            }));
    }

    public async sendSmsMessage(toPhone: string, message, file): Promise<any> {
        let formData: FormData = new FormData();
        if (file) {
            formData.append('file', file, file.name);
        }
        formData.append('toPhone', toPhone);
        formData.append('message', message);
        return await this.handleResponse(this.restService.upload(
            {
                url: '/twilio/sendSmsMessage',
                data: formData
            }));
    }
}
