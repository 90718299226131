import { Component, Inject, ViewChild, Output, EventEmitter, AfterViewInit, Input, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FieldIntlTelComponent } from '../../../deals/fields/field-intl-tel/field-intl-tel.component';
import { FieldEmailsComponent } from '../../../deals/fields/field-emails/field-emails.component';
import { DealService } from '../../../core/services/deals.service';
import { PipelineDealsStoreService } from '../../../core/services/stores/pipeline-deal-store.service';
import { Deal } from '../../../core/models/Deal';
import { UtilsHelper } from '../../../core/utils/utils.helper';
import { PersonPropertiesToUpdate } from '../../../core/enums/PersonPropertiesToUpdate';

@Component({
  selector: 'pd-custom-contacts-modal',
  templateUrl: './custom-contacts-modal.component.html',
  styleUrls: ['./custom-contacts-modal.component.scss']
})
export class CustomContactsModalComponent implements AfterViewInit {
  @ViewChild('fieldIntlTel') fieldIntlTel: FieldIntlTelComponent;
  @ViewChild('fieldEmails') fieldEmails: FieldEmailsComponent;

  @Output() saveData = new EventEmitter<any>();

  public localPhones = [];
  public localEmails = [];
  public deal: Deal;
  public isShowValidationMessages = false;
  public phoneRequired: boolean = false;
  public emailRequired: boolean = false;
  public closeModal: any;
  private confirmAction: any;

  constructor(
    private dealService: DealService,
    private pipelineDealsStoreService: PipelineDealsStoreService,
    private dialogRef: MatDialogRef<CustomContactsModalComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.openModal(data.isPhoneRequired, data.isEmailRequired, data.deal, data.confirmCallback, data.closeModal);
  }

  ngAfterViewInit() {
    this.initFields();
    this.fieldIntlTel.phones = this.localPhones;
    this.fieldEmails.emails = this.localEmails;

    if (!this.localPhones.length) {
      this.fieldIntlTel.addOneMore();
      this.fieldIntlTel.isEdit = true;
    }

    if (!this.localEmails.length) {
      this.fieldEmails.addOneMore();
      this.fieldEmails.isEdit = true;
    }

    this.cdr.detectChanges();
  }

  private checkLabels(data) {
    if (data.length && data[0].label) {
      for (let i = 0; i < data.length; i++) {
        data[i].label = data[i].label.charAt(0).toUpperCase() + data[i].label.slice(1).toLowerCase()
      }
    }
  }

  public openModal(phoneRequired: boolean, emailRequired: boolean, deal: Deal, confirmCallback: any, closeModal: any) {
    this.deal = deal;
    this.localPhones = UtilsHelper.deepClone(deal.phone);
    this.localEmails = UtilsHelper.deepClone(deal.email);

    if (phoneRequired) {
      this.phoneRequired = phoneRequired;
    }
    if (emailRequired) {
      this.emailRequired = emailRequired;
    }

    this.checkLabels(this.localPhones);
    this.checkLabels(this.localEmails);

    this.confirmAction = confirmCallback;
    this.closeModal = closeModal;

    setTimeout(() => {
      this.cdr.detectChanges();
    });
  }

  public confirm(isConfirm: boolean) {
    if (isConfirm) {
      this.save();
    } else {
      this.cancel();
    }
    this.dialogRef.close();
  }

  private async save() {
    if (this.phoneRequired) {
      this.localPhones = this.fieldIntlTel.getPhones().filter(i => i.value.trim().length > 0);
    }
    if (this.emailRequired) {
      this.localEmails = this.fieldEmails.getEmails().filter(i => i.value.trim().length > 0);
    }
    if (!this.phoneRequired && !this.emailRequired) {
      this.localPhones = this.fieldIntlTel.getPhones().filter(i => i.value.trim().length > 0);
      this.localEmails = this.fieldEmails.getEmails().filter(i => i.value.trim().length > 0);
    }

    this.saveData.emit({ phones: this.localPhones, emails: this.localEmails });

    await this.dealService.updateInformation(this.deal.personId, PersonPropertiesToUpdate.Phone, this.localPhones, this.deal.id);
    await this.dealService.updateInformation(this.deal.personId, PersonPropertiesToUpdate.Email, this.localEmails, this.deal.id);

    this.deal.phone = this.localPhones;
    this.deal.email = this.localEmails;

    if (this.pipelineDealsStoreService.deals.value) {
      this.pipelineDealsStoreService.updateDeal(this.deal);
    }

    if (this.confirmAction) {
      this.confirmAction();
    }

    this.dialogRef.close();
  }

  private cancel() {
    this.dialogRef.close();

    if (this.closeModal) {
      this.closeModal();
    }
  }
  
  private initFields() {
    if (!this.fieldIntlTel) {
      this.fieldIntlTel = new FieldIntlTelComponent();
    }
    if (!this.fieldEmails) {
      this.fieldEmails = new FieldEmailsComponent();
    }
  }
    
  public validationCheck() {
    if (this.phoneRequired && this.fieldIntlTel) {
      this.localPhones = this.fieldIntlTel.getPhones().filter(i => i.value.trim().length > 0);

      if (this.localPhones.length > 0) {
        return this.fieldIntlTel.isAllValidCheck();
      }
      return false;
    }

    if (this.emailRequired && this.fieldEmails) {
      this.localEmails = this.fieldEmails.getEmails().filter(i => i.value.trim().length > 0);

      if (this.localEmails.length > 0) {
        return this.fieldEmails.isAllValidCheck();
      }
      return false;
    }

    if (!this.phoneRequired && !this.emailRequired && this.fieldIntlTel && this.fieldEmails) {
      return this.fieldIntlTel.isAllValidCheck() && this.fieldEmails.isAllValidCheck();
    }

    return false;
  }
}