import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { User } from './../../core/models/UserModel';
import { ThirdPartyLinksService } from './../../core/services/third-party-links.service';
import { UserContextService } from './../../core/services/user-context.service';
import { LinkTypes } from 'src/app/core/enums/LinkTypes';
import { ThirdPartyLink } from 'src/app/core/models/ThirdPartyLink';

@Component({
  selector: 'pd-third-party-links',
  templateUrl: './third-party-links.component.html',
  styleUrls: ['./third-party-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThirdPartyLinksComponent implements OnInit {
  @Input() dealId: number;
  @Input() isMyDeal: boolean = true;

  thirdPartyLinks: ThirdPartyLink[] = [];
  maxLinksCount: number = 4;
  currentUser: User;
  deletedThirdPartyLinkIds: number[] = [];
  validationMessages: string;
  isShowValidationMessages: boolean = false;

  linkTypeNames = {
    [LinkTypes.Instagram]: 'Instagram',
    [LinkTypes.WhatsApp]: 'WhatsApp',
    [LinkTypes.vCard]: 'vCard',
    [LinkTypes.Website]: 'Website'
  };

  thirdPartyLinkTypes = [
    { value: LinkTypes.Instagram, name: 'Instagram' },
    { value: LinkTypes.WhatsApp, name: 'WhatsApp' },
    { value: LinkTypes.vCard, name: 'vCard' },
    { value: LinkTypes.Website, name: 'Website' }
  ];

  public isEdit = false;
  private prevLinks: ThirdPartyLink[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private serv: ThirdPartyLinksService,
    private userContextService: UserContextService,
  ) { }

  async ngOnInit() {
    this.isEdit = false;
    this.currentUser = this.userContextService.user.value;
    await this.loadThirdPartyLinks();
    this.cdRef.detectChanges();
  }

  async loadThirdPartyLinks() {
    this.thirdPartyLinks = await this.serv.getThirdPartyLinksByDealId(this.dealId);
    this.prevLinks = this.deepClone(this.thirdPartyLinks);
  }

  addThirdPartyLink() {
    if (this.thirdPartyLinks.length >= this.maxLinksCount) {
      return;
    }
    this.isEdit = true;
    const newLink = new ThirdPartyLink();
    newLink.id = -1;
    newLink.dealId = this.dealId;
    newLink.link = '';
    this.thirdPartyLinks.push(newLink);
  }

  async saveThirdPartyLinks() {
    this.isShowValidationMessages = true;

    if (!this.validate()) {
      return;
    }

    const idsToDelete = this.deletedThirdPartyLinkIds;
    const linksToUpdate = this.thirdPartyLinks.filter(link => !this.deletedThirdPartyLinkIds.includes(link.id));

    if (idsToDelete.length > 0) {
      await this.serv.deleteThirdPartyLinks(idsToDelete);
    }

    if (linksToUpdate.length > 0) {
      await this.serv.updateThirdPartyLinks(linksToUpdate);
    }

    this.deletedThirdPartyLinkIds = [];
    await this.loadThirdPartyLinks();
    this.isEdit = false;
    this.isShowValidationMessages = false;
    this.cdRef.detectChanges();
  }

  deleteThirdPartyLink(thirdPartyLink: ThirdPartyLink) {
    const index = this.thirdPartyLinks.indexOf(thirdPartyLink);
    if (index !== -1) {
      this.thirdPartyLinks.splice(index, 1);
      if (thirdPartyLink.id !== -1) {
        this.deletedThirdPartyLinkIds.push(thirdPartyLink.id);
      }
    }
  }

  toggleEdit() {
    this.isEdit = !this.isEdit;
    if (!this.isEdit) {
      this.cancelThirdPartyLink();
    }
  }

  cancelThirdPartyLink() {
    this.thirdPartyLinks = this.deepClone(this.prevLinks);
    this.deletedThirdPartyLinkIds = [];
    this.isEdit = false;
    this.isShowValidationMessages = false;
  }

  getLinkTypeName(type: LinkTypes): string {
    return this.linkTypeNames[type] || 'Unknown';
  }

  private deepClone(array: ThirdPartyLink[]): ThirdPartyLink[] {
    return JSON.parse(JSON.stringify(array));
  }

  public get canAddMoreLinks(): boolean {
    return this.thirdPartyLinks.length < this.maxLinksCount;
  }

  private validate(): boolean {
    if (!this.isShowValidationMessages) {
      return true;
    }

    const messages = [];

    if (this.hasDuplicateLinks()) {
      messages.push('Duplicate links are not allowed.');
    }

    if (this.hasDuplicateTypes()) {
      messages.push('Duplicate types are not allowed.');
    }

    if (this.hasEmptyLinks()) {
      messages.push('Link cannot be empty.');
    }

    if (this.hasEmptyTypes()) {
      messages.push('Link type cannot be empty.');
    }

    this.validationMessages = messages.join(' ');
    return messages.length === 0;
  }

  private hasDuplicateLinks(): boolean {
    const links = this.thirdPartyLinks.map(link => link.link.trim().toLowerCase());
    return new Set(links).size !== links.length;
  }

  private hasDuplicateTypes(): boolean {
    const types = this.thirdPartyLinks.map(link => link.linkType);
    return new Set(types).size !== types.length;
  }

  private hasEmptyLinks(): boolean {
    return this.thirdPartyLinks.some(link => !link.link || link.link.trim().length === 0);
  }

  private hasEmptyTypes(): boolean {
    return this.thirdPartyLinks.some(link => link.linkType === undefined || link.linkType === null);
  }
}