import { Component, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'pd-field-emails-input',
  templateUrl: './field-emails-input.component.html',
  styleUrls: ['./field-emails-input.component.scss']
})
export class FieldEmailsInputComponent {
  @ViewChild('inputField') inputField: ElementRef;
  @Input() email: string;
  @Output() emailChange = new EventEmitter<Object>();

  public isSpinner = false;
  public isShowValidationMessages = false;
  public validationMessages = "";

  emailControl = new FormControl('', [
    Validators.required, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$'), this.noCyrillic])

  constructor() { }

  public resetValidation() {
    this.isShowValidationMessages = false;
  }

  validation() {
    if (this.emailControl.touched) {
      this.isShowValidationMessages = this.emailControl.errors &&
        (this.emailControl.errors.email
          || this.emailControl.errors.pattern
          || this.emailControl.errors.cyrillic);

      if (this.emailControl.errors && this.emailControl.errors.pattern) {
        this.validationMessages = "Invalid email format"
      }

      if (this.emailControl.errors && this.emailControl.errors.cyrillic) {
        this.validationMessages = "Cyrillic letters are not allowed"
      }
    }
    else {
      this.isShowValidationMessages = false;
    }
  }

  public checkIsValid() {
    return !this.isShowValidationMessages;
  }

  private noCyrillic(control) {
    if (/[а-яё]/i.test(control.value)) {
      return { 'cyrillic': true }
    }

    return null;
  }

  public onChange() {
    this.resetValidation();
    this.validation();
    let email = this.email;
    this.emailChange.emit({ email: email });
  }
}
