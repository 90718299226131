import { PersonContactInfo } from './PersonContactInfo';

export class DealDetailsDto {
    public dealId: number;
    public title: string;
    public firstName: string;
    public lastName: string;
    public birthday?: Date;
    public stageId: number;
    public previousLabeledStageId?: number;
    public pipelineId: number;
    public ownerId: number;
    public personId: number;
    public personWhoReferred: string;
    public personWhoReferredId: number;
    public isNotUseAsCommonConnection: boolean;
    public isDontSendPromotionalEmails: boolean;
    public isDontSendPartnerEmails: boolean;
    public isDontSendWhatsapp: boolean;
    public isDontSendSms: boolean;
    public isPartnerRepliedToBDayEmail: boolean;
    public clientId: number;

    public genderId: number;
    public company: string;
    public companyId: number;
    public companyAddress: string;
    public homeAddress: string;
    public position: string;
    public positionId: number;
    public location: string;
    public locationId: number;
    public linkedInURL: string;
    public clientGroupId: number | null;
    public emails: PersonContactInfo[];
    public phones: PersonContactInfo[];
    public called: boolean;
    public dateOfCall?: Date;
    public isOpenOrderNotified: boolean;
    public isDeliveryEmailSent: boolean;
    public isIncreasedRecentlyContactedRotting?: boolean;
    public isForClothier?: boolean;
    public isOrderShipped?: boolean;
    public bookedById?: number;
    public bookedByName: string;
    public trackingLink: string;
    public preferToContactByEmail: boolean;
    public clientRating: string;
    public timeZone: string;
    public increasingRottenTimeCounter: number = 0;
    public maxIncreasingRottenTimeCounter?: number;
    public reselectRottenTimeCounter: number = 0;
    public isRotting = false;
    public meetingDateUtc: Date;
    public latitude: string;
    public longitude: string;
    public filesCount: number;
    public filesCountPdf: number;
    public wardrobeImageCount: number;
    public clientLifetimeSpend: number;
    public lastExportDealDate?: Date;

    public partnerFirstName: string;
    public partnerLastName: string;
    public partnerEmail: string;
    public partnerPhone: string;
    public partnerToken: string;
}
