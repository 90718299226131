import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpRestService } from './http-client/http-rest-client.service';
import { ThirdPartyLink } from '../models/ThirdPartyLink';

@Injectable()
export class ThirdPartyLinksService extends BaseService {

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getThirdPartyLinksByDealId(dealId: number): Promise<Array<ThirdPartyLink>> {
        return await this.handleResponse(this.restService.get({
            url: '/thirdPartyLinks?dealId=' + dealId
        }));
    }

    public async updateThirdPartyLinks(thirdPartyLinks: ThirdPartyLink[]) {
        return await this.handleResponse(this.restService.post({
            url: '/thirdPartyLinks/updateThirdPartyLinks',
            data: thirdPartyLinks
        }));
    }

    public async deleteThirdPartyLinks(ids: number[]) {
        return await this.handleResponse(this.restService.post({
            url: '/thirdPartyLinks/deleteThirdPartyLinks',
            data: ids
        }));
    }
}
