import { NoteModel } from "../Note";
import { Participant } from "../Participant";
import { SameBuilding } from "../SameBuilding";
import { PersonContactInfo } from "./PersonContactInfo";
import { PersonShortInfoDto } from "./PersonShortInfoDto";

export class FilteredDealDto {
    public dealId: number;
    public title: string;
    public stageId: number;
    public previousLabeledStageId?: number;
    public ownerId: number;
    public personId: number;
    public personWhoReferred: string;
    public personWhoReferredId: number;
    public isRecentlyRequestedHotlist: boolean;
    public lastRequestHotlistDate: string;
    public lastSeasonalCheckEmailSentDate: string;
    public participantsCount: number;
    public sameBuildingCount: number;
    public filesCount: number;
    public filesCountPdf: number;
    public isNotesExist: boolean;
    public wardrobeImageCount: number;
    
    public isRotting: boolean;
    public isNeedToReload: boolean;

    public person: PersonShortInfoDto;
    public genderId: number;
    public company: string;
    public companyId: number;
    public companyAddress: string;
    public homeAddress: string;
    public position: string;
    public positionId: number;
    public location: string;
    public locationId: number;
    public linkedInURL: string;
    public emails: PersonContactInfo[];
    public phones: PersonContactInfo[];
    public stageOrderNumber: number;
    public pipelineId: number;
    public stageName: string;
    public userName: string;
    public called: boolean;
    public dateOfCall?: Date;
    public isOpenOrderNotified: boolean;
    public isDeliveryEmailSent: boolean;
    public birthday?: Date;
    public isIncreasedRecentlyContactedRotting?: boolean;
    public isForClothier?: boolean;
    public isOrderShipped?: boolean;
    public bookedById?: number;
    public trackingLink: string;
    public preferToContactByEmail: boolean;
    public clientRating: string;
    public increasingRottenTimeCounter: number = 0;
    public maxIncreasingRottenTimeCounter?: number;
    public reselectRottenTimeCounter: number = 0;
    public meetingDateUtc: Date;
    public noteList?: NoteModel[];
    public participantList?: Participant[];
    public sameBuildingList?: SameBuilding[];
    
    public latitude: string = null;
    public longitude: string = null;

    public viewOrder: number = 0;
    public rottenTime: Date;
    public modified: Date;
    public clientLifetimeSpend: number = 0;
    public lastExportDealDate?: Date;
}