import { Injectable } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";
import { User } from "../models/UserModel";
import { UserData } from "../models/UserData";

@Injectable()
export class UsersService extends BaseService {
    public ClothierEaConnectionValidationMessage = "Warning! You cannot remove user/update role EA/Admin because clothier is assigned under him.";

    constructor(private restService: HttpRestService) {
        super();
    }

    public async getUsersWithRedDealsByRoleAndPipeline(pipelineId: number, roleId: number): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/user/usersWithRottenDeals',
            data: { PipelineId: pipelineId, RoleId: roleId }
        }));
    }

    public async getUsers(): Promise<User[]> {
        return await this.handleResponse(this.restService.get({
            url: '/user/getUsers'
        }));
    }

    public async closeUser(userId: number, ownerId: number): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/account/closeUser',
            data: { UserId: userId, OwnerId: ownerId }
        }));
    }

    public async getUserById(userId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({
            url: '/user/getUserById?userId=' + userId
        }));
    }

    public async addUser(user: any): Promise<any> {
        return await this.restService.post({
            url: '/account/addUser',
            data: user
        });
    }

    public async updateUser(user: User): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/account/updateUser',
            data: user
        }));
    }

    public async updateOpenCC(user: User): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/user/openCC',
            data: user
        }));
    }

    public async changePassword(changePasswordModel: any) {
        return await this.handleResponse(this.restService.post({
            url: '/account/changePassword',
            data: changePasswordModel
        }));
    }

    public async updateClothierToEaConnections(users: User[]): Promise<any> {
        return await this.handleResponse(this.restService.post({
            url: '/user/clothierToEaConnections',
            data: users
        }));
    }

    public async checkIsEaHasConnections(userId: number): Promise<any> {
        return await this.handleResponse(this.restService.get({
            url: '/user/checkIsEaHasConnections?userId=' + userId
        }));
    }

    public async getUsersInHeader(): Promise<UserData> {
        const result = await this.handleResponse(this.restService.get({
            url: '/user/getUserData'
        }));

        if (!result) {
            return null;
        }

        var obj = new UserData();
        obj.name = result.name;
        obj.company_name = 'LGFG FASHION HOUSE';
        obj.referralLink = result.referralLink;
        return obj;
    }
}
