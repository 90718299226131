import { UserContextService } from './../core/services/user-context.service';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DateHelper } from "../core/utils/date.helper";
import { User } from "../core/models/UserModel";
import { UploadImageService } from '../core/services/upload-image.service';
import { SignalrService } from '../core/services/signalr.service';
import { AlertTypes } from '../core/enums/AlertTypes';
import { RolesEnum } from '../core/enums/RolesEnum';
import { FileUtil } from '../core/utils/file.util';
import { DealUtil } from '../core/utils/deal.util';
import { TwilioService } from '../core/services/twilio.service';
import { AlertService } from '../core/services/alert.service';
import { Chat } from '../core/models/chat-models/Chat';
import { Message } from '../core/models/chat-models/Message';
import { TwilioHelper } from '../core/utils/twilio.helper';

@Component({
  selector: 'chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class WhatsappChatComponent implements OnInit {
  @ViewChild("targetHeader") targetHeader: ElementRef;
  @ViewChild("targetMessagesList") targetMessagesList: ElementRef;
  @ViewChild("targetMessagesInput") targetMessagesInput: ElementRef;
  @ViewChild("targetTextarea") targetTextarea: ElementRef;
  @ViewChild("targetScroll") targetScroll: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('fullscreenView') fullscreenView: ElementRef;
  @ViewChild('fullscreenImg') fullscreenImg: ElementRef;

  @Input() public dealId: number;
  @Input() public dealTitle: string;
  @Input() public isDontWhatsappMessage: boolean = false;
  @Input() public isDontSmsMessage: boolean = false;
  @Input() public isCandidateConversation: boolean = false;

  public chatList: Chat[] = [];
  public activeChat?: Chat;
  public newMessageText = '';
  public selectedFile: File;
  public pervUrl: any;
  public timer: string = '';
  public fileAccept = UploadImageService.allowedImageTypes.join(',');

  public isSpinner: boolean = false;
  public isMessageSending: boolean = false;
  public isGlobalChat: boolean = false;

  public FileHelper = FileUtil;
  public DealUtil = DealUtil;
  public TwilioHelper = TwilioHelper;
  public DateHelper = DateHelper;

  public isShowTextIfNoConversations: boolean = false;
  public textIfNoConversations = `The client doesn't have any valid phone numbers. Please change the phone numbers in "Deal" tab and get back to this.`;
  public textIfNoConversationsGlobalChat = `No messages were sent to your clients yet. Please, try later.`;

  private currentUser: User;

  statusPriority = [
    { name: "accepted", priority: 0 },
    { name: "queued", priority: 1 },
    { name: "sent", priority: 2 },
    { name: "delivered", priority: 3 },
    { name: "read", priority: 4 },
    { name: "undelivered", priority: 5 },
    { name: "failed", priority: 6 },
  ];

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly signalRService: SignalrService,
    private readonly twilioService: TwilioService,
    private sanitizer: DomSanitizer,
    private userContextService: UserContextService,
    private readonly alertService: AlertService,
  ) { }

  ngOnInit(): void {
    this.signalRService.startConnection();
    this.addFromLifetimeListener();
    this.addToCallbackListener();
    this.isSpinner = true;

    this.currentUser = this.userContextService.user.value;
  }

  async ngAfterViewInit() {
    this.isGlobalChat = !this.dealId;
    this.calcMessagesList();

    if (this.isGlobalChat) {
      if (this.currentUser.role_id === RolesEnum.Recruiter) {
        this.setChatData(await this.twilioService.getAllCandidatesHistory());
      }
      else {
        this.setChatData(await this.twilioService.getAllHistory());
      }
    }
    else {
      if (this.isCandidateConversation) {
        this.setChatData(await this.twilioService.getCandidatesHistory(this.dealId));
      }
      else {
        this.setChatData(await this.twilioService.getHistory(this.dealId));
      }
    }
  }

  public isSessionActive() {
    if (this.timer) {
      return true;
    }
    return false;
  }

  public openInput() {
    this.resetFileInput();
    this.fileInput.nativeElement.click();
  }

  public changeListener(files: FileList) {
    if (files && files.length > 0) {
      var splitArray = files[0].name.split('.');
      var fileType = splitArray[splitArray.length - 1];
      if (!(fileType && (fileType.toLowerCase() === "png"
        || fileType.toLowerCase() === "jpg"
        || fileType.toLowerCase() === "jpeg"
        || fileType.toLowerCase() === "gif"
        || fileType.toLowerCase() === "webp"
      ))) {
        this.alertService.showAlert(AlertTypes.Error, 'Wrong file format. Please upload image file.', 'Noted');
        return;
      }
      this.selectedFile = files[0];
      this.pervUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.selectedFile));
    }
  }

  public onChangeChat(chat: Chat) {
    const oldActiveChat = this.activeChat;
    this.activeChat = chat;
    if (oldActiveChat && chat && oldActiveChat.id != chat.id) {
      this.checkClientConversationWarning();
    }
    this.scrollToEnd();
  }

  public sendMessage() {
    if (this.newMessageText.trim() === '' && !this.selectedFile) {
      return;
    }

    const chat = this.activeChat;
    if (!chat) {
      return;
    }

    this.isMessageSending = true;

    if (chat.isWhatsAppConversation) {
      this.twilioService.sendWhatsappMessage(chat.chatPhone, this.newMessageText, this.selectedFile)
        .then((response) => {

          if (!response.isSuccessful) {
            this.alertService.showAlert(AlertTypes.Error, response.errorMessage, 'Noted');
          }

          this.newMessageText = '';
          this.resetFileInput();
          this.isMessageSending = false;
          this.autoGrow();
        })
    }
    else {
      this.twilioService.sendSmsMessage(chat.chatPhone, this.newMessageText, this.selectedFile)
        .then((response) => {

          if (!response.isSuccessful) {
            this.alertService.showAlert(AlertTypes.Error, response.errorMessage, 'Noted');
          }

          this.newMessageText = '';
          this.resetFileInput();
          this.isMessageSending = false;
          this.autoGrow();
        })
    }
  }

  public removeAttach() {
    this.resetFileInput();
  }

  public autoGrow() {
    if (!this.targetTextarea) {
      return;
    }
    let textArea = this.targetTextarea.nativeElement;
    setTimeout(() => {
      if (textArea.scrollHeight > textArea.clientHeight) {
        textArea.style.height = textArea.scrollHeight + "px";
      } else {
        textArea.style.height = 0 + "px";
        textArea.style.height = textArea.scrollHeight + "px";
      }

      this.calcMessagesList()
    }, 100);
  }

  public openFullscreen(event) {
    const fullscreen = this.fullscreenView.nativeElement;
    const fullscreenImg = this.fullscreenImg.nativeElement;
    fullscreenImg.src = event.target.src;
    fullscreen.setAttribute('style', `display: block`);
  }

  public closeFullscreen() {
    const fullscreen = this.fullscreenView.nativeElement;
    fullscreen.setAttribute('style', `display: none`);
  }

  private setChatData(result: Chat[]) {
    this.chatList = result;

    if (!this.checkAllowedConversations(this.chatList)) {
      return;
    }

    this.sortChats();

    this.activeChat = this.chatList[0];
    this.checkClientConversationWarning();

    this.newMessageText = '';

    this.autoGrow();
    this.scrollToEnd();
    this.startTimer();

    this.isSpinner = false;
  }

  public getActiveChatTitle() {
    if (!this.activeChat)
      return;

    return this.activeChat.name ? `${this.activeChat.name} (${this.activeChat.chatPhone})` : this.activeChat.chatPhone;
  }

  private addFromLifetimeListener() {
    this.signalRService.hubConnection.on('Receive', (newMessage: Message) => {
      const chat = this.chatList.find(c => c.chatPhone && (c.chatPhone === newMessage.messagePhone) && (c.isWhatsAppConversation === newMessage.isWhatsAppMessage)); //TODO: check is whatsapp
      if (!chat) {
        if (!this.isGlobalChat) {
          return;
        }

        if (this.isCandidateConversation) {
          this.twilioService.getCandidatesHistory(this.dealId).then((response) => {
            this.onReceiveNewChat(response);
          })
        }
        else {
          this.twilioService.getHistory(this.dealId).then((response) => {
            this.onReceiveNewChat(response);
          })
        }
      }

      this.onReceiveNewMessage(newMessage);
    });
  }

  private addToCallbackListener() {
    this.signalRService.hubConnection.on('Callback', (data) => {
      const chat = this.chatList.find(subArray => !!subArray.messages.find(item => item.sid == data.sid));

      if (!chat) {
        return;
      }

      const message = chat.messages.find(item => item.sid === data.sid);
      const lastMessage = chat.lastMessage;

      if (message) {
        this.updateMessageStatus(message, data);
      }

      if (lastMessage && lastMessage.sid == data.sid) {
        this.updateMessageStatus(lastMessage, data);
      }

      this.ref.detectChanges();
    });
  }

  private updateMessageStatus(message: Message, data) {
    const oldPriority = this.statusPriority.find(s => s.name === message.status);
    const newPriority = this.statusPriority.find(s => s.name === data.status);

    if (oldPriority && newPriority) {
      if (newPriority.priority > oldPriority.priority) {
        message.status = data.status;
      }
    }
    else {
      message.status = data.status;
    }

    message.errorMessage = data.errorMessage;
  }

  private onReceiveNewMessage(newMessage: Message) {
    const chat = this.chatList.find(c => c.chatPhone && (c.chatPhone === newMessage.messagePhone) && (c.isWhatsAppConversation === newMessage.isWhatsAppMessage)); //TODO: check is whatsapp

    if (!chat) {
      return;
    }

    if (!chat.messages) {
      chat.messages = new Array<Message>();
    }

    chat.messages.push(newMessage);
    chat.lastMessage = newMessage;

    this.sortChats();

    this.ref.detectChanges();
    if (chat && this.activeChat && chat.id == this.activeChat.id) {
      this.scrollToEndSmoothly();
    }
  }

  private onReceiveNewChat(recipientList) {
    if (recipientList[0]) {
      this.chatList.push(recipientList[0])

      if (this.chatList.length === 1) {
        this.setChatData(this.chatList);
      }
      else {
        this.sortChats();
      }
    }
  }

  private startTimer() {
    setInterval(() => {
      if (!this.activeChat || !this.activeChat.messages || !this.activeChat.isWhatsAppConversation) {
        this.timer = '';
        return;
      }

      const currentTime = new Date().getTime();
      const lastClientMessage = this.activeChat.messages
        .filter(m => m.isClientMessage)
        .sort((a, b) => DateHelper.utcStringToLocalDate(a.time).getTime() - DateHelper.utcStringToLocalDate(b.time).getTime())
        .pop();

      if (!lastClientMessage) {
        this.timer = '';
        return;
      }

      const timeDiff = currentTime - DateHelper.utcStringToLocalDate(lastClientMessage.time).getTime();
      const timeLeft = DateHelper.dayToMiliseconds - timeDiff;

      if (timeLeft < 1) {
        this.timer = '';
        return;
      }

      const hours = Math.floor(timeLeft / DateHelper.hourToMiliseconds);
      const minutes = Math.floor((timeLeft % DateHelper.hourToMiliseconds) / DateHelper.minuteToMiliseconds);
      const seconds = Math.floor((timeLeft % DateHelper.minuteToMiliseconds) / DateHelper.secondToMiliseconds);

      this.timer = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 100);
  }

  private sortChats() {
    if (!this.chatList)
      return;

    this.chatList = this.chatList.sort((a, b) => {
      if (a.lastMessage === null && b.lastMessage === null) {
        return 0;
      } else if (a.lastMessage === null) {
        return 1;
      } else if (b.lastMessage === null) {
        return -1;
      } else {
        const aTime = DateHelper.utcStringToLocalDate(a.lastMessage.time).getTime();
        const bTime = DateHelper.utcStringToLocalDate(b.lastMessage.time).getTime();
        return bTime - aTime;
      }
    });
  }

  private resetFileInput() {
    this.selectedFile = null;
    this.pervUrl = null;
    this.fileInput.nativeElement.value = "";
  }

  private scrollToEndSmoothly() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start", behavior: "smooth" });
    }, 100);
  }

  private scrollToEnd() {
    setTimeout(() => {
      this.targetScroll.nativeElement.scrollIntoView({ block: "start" });
    }, 1);
  }

  private calcMessagesList() {
    const offset = (this.isGlobalChat) ? -2 : 0;
    const height = (this.targetMessagesInput.nativeElement.clientHeight + this.targetHeader.nativeElement.clientHeight) + offset;
    this.targetMessagesList.nativeElement.setAttribute('style', `height: calc(100% - ${height}px)`);
  }

  public getMessageClasses(message: Message) {
    if (message.isClientMessage) {
      return 'message sender bubble-arrow-sender';
    }
    return 'message receiver bubble-arrow-receiver';
  }

  public getTimeClasses(message: Message) {
    if (message.isClientMessage) {
      return 'message-time-sender';
    }
    return 'message-time-receiver';
  }

  public get isAllowSendMessage() {
    return this.dealId && !this.isGlobalChat && !this.isCandidateConversation && this.currentUser
      && (this.currentUser.role_id == RolesEnum.MasterLead
        || this.currentUser.role_id == RolesEnum.EA
        || this.currentUser.role_id == RolesEnum.EaCaller
        || (this.currentUser.role_id === RolesEnum.Admin && this.currentUser.isWhatsappChat));
  }

  public getErrorMessageTooltip(message) {
    return message.errorMessage;
  }

  private checkClientConversationWarning() {
    if (this.activeChat && this.activeChat.isWhatsAppConversation && !this.isGlobalChat && this.isDontWhatsappMessage) {
      this.alertService.showAlert(AlertTypes.Warning, 'Important! The client asked not to send WhatsApp messages', 'Noted, I want to proceed');
    }
    if (this.activeChat && !this.activeChat.isWhatsAppConversation && !this.isGlobalChat && this.isDontSmsMessage) {
      this.alertService.showAlert(AlertTypes.Warning, 'Important! The client asked not to send SMS messages', 'Noted, I want to proceed');
    }
  }

  private checkAllowedConversations(chatList: Chat[]): boolean {
    if (chatList && chatList.length == 0) {
      this.isShowTextIfNoConversations = true;
      return false;
    }
    this.isShowTextIfNoConversations = false;
    return true;
  }
}