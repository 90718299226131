<div>
  <div class="email-input">
    <input #inputField type="email" name="email" ngModel email [formControl]="emailControl" [(ngModel)]="email"
      onDrag="return false" onDrop="return false" autocomplete=off
      [ngClass]="{'input-error': isShowValidationMessages }" (input)="validation()" (blur)="validation()"
      (change)="onChange()">
  </div>
  @if (isShowValidationMessages) {
  <div class="row alert-row error-message-wrapper">
    <div class="alert alert-danger error-message">{{validationMessages}}</div>
  </div>
  }
</div>