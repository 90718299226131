@if (item) {
<div class="wrapper">
    @if (spinner) {
    <div class="spinner">
        <mat-spinner></mat-spinner>
    </div>
    }
    @if (tabs) {

    <ul class="nav nav-tabs justify-content-left">
        @for (tab of tabs; track tab) {
        <li class="nav-item">
            @if (isTabVisible(tab.id)) {
            <a (click)="onTabClick(tab)" class="nav-link" [ngClass]="tab.isActive ? 'active': ''">{{tab.name}}</a>
            }
        </li>
        }
    </ul>
    @if (tabs[0].isActive) {
    <div>
        @if (item !== null) {
        <div class="row">
            <div class="col-lg-12 col-xl-8">
                <div class="row">
                    <div class="col-11 custom-card-body">
                        @if (currentUser.role_id !== RolesEnum.SystemAccount && currentUser.role_id !==
                        RolesEnum.MasterLead) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Name
                            </div>
                            <div class="col">{{item.name}}</div>
                        </div>
                        }
                        @if (currentUser.role_id === RolesEnum.SystemAccount || currentUser.role_id ===
                        RolesEnum.MasterLead) {
                        <div class="row hover">
                            <div class="col-2 text">
                                FirstName
                            </div>
                            @if (item.firstName && addFirstNameValue === false) {
                            <div class="col-10 notes">
                                {{item.firstName}}
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.FirstName)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            }
                            @if (addFirstNameValue === true) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.firstName}}" #textFirstName>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.FirstName)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.FirstName, textFirstName.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if ((!item.firstName) && addFirstNameValue === false && (currentUser.role_id ===
                            RolesEnum.SystemAccount || currentUser.role_id === RolesEnum.MasterLead)) {
                            <div class="col-8 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.FirstName)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        }
                        @if (currentUser.role_id === RolesEnum.SystemAccount || currentUser.role_id ===
                        RolesEnum.MasterLead) {
                        <div class="row hover">
                            <div class="col-2 text">
                                LastName
                            </div>
                            @if (item.lastName && addLastNameValue === false) {
                            <div class="col-10 notes">
                                {{item.lastName}}
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.LastName)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            }
                            @if (addLastNameValue === true) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.lastName}}" #textLastName>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.LastName)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.LastName, textLastName.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if ((!item.lastName) && addLastNameValue === false && (currentUser.role_id ===
                            RolesEnum.SystemAccount || currentUser.role_id === RolesEnum.MasterLead)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.LastName)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        }

                        <div class="row hover">
                            <div class="col-2 text">
                                Company
                            </div>

                            @if (item.company && addCompanyValue === false) {
                            <div class="col-10 notes">
                                {{item.company}}
                                @if ((isAdmin || isMyDeal || isAllowedFieldsChange)) {
                                <div class="edit-button">
                                    <button type="button" class="open-edit"
                                        (click)="changeStatus(personPropertiesToUpdate.Company)">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if (!item.company && addCompanyValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.Company)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        <div class="row hover">
                            <div class="col-2 text">
                                Company address
                            </div>
                            @if (item.companyAddress && addCompanyAddressValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange === true)) {
                            <div class="col-10 notes">
                                {{item.companyAddress}}
                                <div class="edit-button"
                                    (click)="changeStatus(personPropertiesToUpdate.CompanyAddress)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            }
                            @if ((!item.companyAddress) && addCompanyAddressValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange === true)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.CompanyAddress)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover">
                            <div class="col-2 text">
                                Home address
                            </div>
                            @if (item.homeAddress && addHomeAddressValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange === true)) {
                            <div class="col-10 notes">
                                {{item.homeAddress}}
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.HomeAddress)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                            </div>
                            }
                            @if ((!item.homeAddress) && addHomeAddressValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange === true)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.HomeAddress)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        <div class="row hover">
                            <div class="col-2 text">
                                Position
                            </div>
                            @if (positionMode !== valueMode.Label) {
                            <div class="col-6 editInProgress">
                                @if (positionMode === valueMode.Edit) {
                                <pd-custom-select-filter #positionFilter
                                    (loadDataEvent)="getPositionsFilterData($event)"
                                    (autoCompleteEvent)="getPositionsAutocomplete($event)"
                                    (isSelectedItem)="onPositionsChanged()" [dataLabel]="item.position"
                                    [filterType]="filterType.Position" [isMultiple]="false"
                                    [selectText]="item.position">
                                </pd-custom-select-filter>
                                }
                                @if (positionMode === valueMode.Edit) {
                                <pd-add-value (addValue)="changePositionMode(valueMode.Add); selectedPosition=''">
                                </pd-add-value>
                                }
                                @if (positionMode === valueMode.Add) {
                                <div class="edit-input">
                                    <input type="text" [(ngModel)]="selectedPosition">
                                </div>
                                }
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.Position)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.Position, selectedPosition)">Save</button>
                                </div>
                            </div>
                            }
                            @if (positionMode === valueMode.Label) {
                            <div class="col-10 notes">
                                {{item.position}}
                                @if (item.position && (isAdmin || isMyDeal || isAllowedFieldsChange ===
                                true)) {
                                <div class="edit-button">
                                    @if (item.position) {
                                    <button type="button" class="open-edit"
                                        (click)="changePositionMode(valueMode.Edit)">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                    }
                                </div>
                                }
                                @if (!item.position && (isAdmin || isMyDeal || isAllowedFieldsChange ===
                                true)) {
                                <div class="add-notes-value" style="width: 100%;"
                                    (click)="changePositionMode(valueMode.Edit)">
                                    <div class="add-value">+Add value</div>
                                </div>
                                }
                            </div>
                            }
                        </div>

                        <div class="row hover" [ngClass]="{'error': isUrlExist, 'no-error': !isUrlExist }">
                            <div class="col-2 text">
                                LinkedinUrl
                            </div>
                            @if (item.linkedinUrl && addlinkedinUrlValue === false) {
                            <div class="col-10 notes url">
                                <div class="url-content">
                                    <a href="{{item.linkedinUrl}}" target="_blank">{{item.linkedinUrl}}</a>
                                    <br>
                                    @if (isUrlExist) {
                                    <span class="error-text">{{urlError}}</span>
                                    }
                                </div>
                                @if (isAdmin || isMyDeal || currentUser.role_id === RolesEnum.SystemAccount) {
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.LinkedinURL)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if (addlinkedinUrlValue === true) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.linkedinUrl}}" #textlinkedinUrl>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.LinkedinURL)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.LinkedinURL, textlinkedinUrl.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if (!item.linkedinUrl && !addlinkedinUrlValue && (isAdmin || isMyDeal)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.LinkedinURL)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover">
                            <div class="col-2 text">
                                Booked By
                            </div>
                            @if (bookedBy) {
                            <div class="col-10 notes">
                                {{bookedBy}}
                            </div>
                            }
                        </div>
                        <div class="row hover">
                            <div class="col-2 text">
                                Person Who Referred
                            </div>
                            @if (dealsPerson.personWhoReferred && addPersonWhoReferredValue === false) {
                            <div class="col-10 notes">
                                @if (dealsPerson.personWhoReferredId) {
                                <a [href]="getDealLink(dealsPerson.personWhoReferredId)"
                                    target="_blank">{{dealsPerson.personWhoReferred}}</a>
                                }
                                @if (!dealsPerson.personWhoReferredId) {
                                <div>{{dealsPerson.personWhoReferred}}
                                </div>
                                }
                                @if (currentUser.role_id === RolesEnum.SystemAccount) {
                                <div class="edit-button"
                                    (click)="changeStatus(personPropertiesToUpdate.PersonWhoReferred)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if (addPersonWhoReferredValue === true) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{dealsPerson.personWhoReferred}}" #textPersonWhoReferred>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.PersonWhoReferred)">Cancel</button>
                                    <button class="save"
                                        (click)="updatePersonWhoReferred(textPersonWhoReferred.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if (!dealsPerson.personWhoReferred && addPersonWhoReferredValue === false &&
                            currentUser.role_id === RolesEnum.SystemAccount) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.PersonWhoReferred)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover location-container">
                            <div class="col-2 text">
                                {{getPreviousLabeledStageDescription()}}
                            </div>
                            @if (dealsPerson.previous_labeled_stage_id) {
                            <div class="col-10 notes">
                                {{getLabeledStageName(dealsPerson.previous_labeled_stage_id)}}
                            </div>
                            }
                        </div>

                        <div class="row hover location-container">
                            <div class="col-2 text">
                                Location
                            </div>
                            @if (item.location && addLocationValue === false) {
                            <div class="col-10 notes">
                                {{item.location}}
                                @if ((isAdmin || isMyDeal || isAllowedFieldsChange === true)) {
                                <div class="edit-button">
                                    <button type="button" class="open-edit"
                                        (click)="changeStatus(personPropertiesToUpdate.Location)">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if ((!item.location) && addLocationValue === false && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange === true)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.Location)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        <div class="row hover phone phone-emails">
                            <div class="col-2 text">
                                Phone
                            </div>
                            @if (item.phone.length > 0 && item.phone[0].value && addPhoneValue === false) {
                            <div class="col-10 notes">
                                <div class="phones">
                                    @for (phone of item.phone; track phone) {
                                    <div>
                                        @if (phone.value !== '' && phone.label !== ' ') {
                                        <div>
                                            {{phone.value}} ({{phone.label | pdCapitalize}})
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                                @if (addPhoneValue === false && item.phone.length > 0 && item.phone[0].value && (isAdmin
                                || isMyDeal || currentUser.role_id === RolesEnum.SystemAccount)) {
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.Phone)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }

                            @if (addPhoneValue === true) {
                            <div class="col-10 editInProgress">
                                <pd-field-intl-tel
                                    (phonesSave)="updatePersonContactsInfo(item.id, personPropertiesToUpdate.Phone, $event)"
                                    (phonesCancel)="phonesCancel()" [phones]="item.phone">
                                </pd-field-intl-tel>
                            </div>
                            }

                            @if (item.phone.length > 0 && !item.phone[0].value && addPhoneValue === false && (isAdmin ||
                            isMyDeal)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.Phone)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        <div class="row hover phone phone-emails">
                            <div class="col-2 text">
                                Email
                            </div>
                            @if (item.email.length > 0 && item.email[0].value && addEmailValue === false) {
                            <div class="col-10 notes">
                                <div class="emails">
                                    @for (email of item.email; track email) {
                                    <div>
                                        @if (email.value !== '' && email.label !== ' ') {
                                        <div>
                                            {{email.value}} ({{email.label | pdCapitalize}})
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                                @if (addEmailValue === false && item.email.length > 0 && item.email[0].value && (isAdmin
                                || isMyDeal || currentUser.role_id === RolesEnum.SystemAccount)) {
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.Email)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }

                            @if (addEmailValue === true) {
                            <div class="col-10 editInProgress">
                                <pd-field-emails
                                    (emailsSave)="updatePersonContactsInfo(item.id, personPropertiesToUpdate.Email, $event)"
                                    (emailsCancel)="emailsCancel()" [emails]="item.email">
                                </pd-field-emails>
                            </div>
                            }

                            @if (item.email.length > 0 && !item.email[0].value && addEmailValue === false && (isAdmin ||
                            isMyDeal)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.Email)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        @if (pipelineId != STAGES_AND_PIPELINES.NewProspectsPipeline.id && pipelineId !=
                        STAGES_AND_PIPELINES.ClothierMeetingConfirmPipeline.id) {
                        <div class="row hover">
                            <div class="col-2 text" #emailTitle>
                                Birthday
                            </div>
                            <div class="col-10 birthday">
                                <mat-form-field class="mat-input-date-picker">
                                    <input matInput [matDatepicker]="picker" [disabled]="(!isAdmin && !isMyDeal)"
                                        [max]="getToday()" [value]="item.birthday" placeholder="Choose a date"
                                        (dateChange)="selectBirthdayDate(item.id, $event.value)">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        }

                        <div class="row hover">
                            <div class="col-2 text">
                                Stage
                            </div>
                            <div class="col-10 stage">
                                <div>
                                    <mat-form-field class="stage-select-global">
                                        <mat-select [(ngModel)]="dealsPerson.stage_id" (click)="openStageSelect()"
                                            panelClass="stage-panel-class-inject"
                                            (selectionChange)="updateStageConfirmation($event.value)"
                                            (keydown)="$event.stopPropagation()"
                                            [disabled]="(!isAdmin && !isMyDeal) || !isAllowedToChangeStage">
                                            @for (pipeline of pipelines; track pipeline) {
                                            <mat-optgroup [label]="pipeline.name">
                                                @for (stage of pipeline.stages; track stage) {
                                                <mat-option [value]="stage.id">
                                                    {{ stage.name }}
                                                </mat-option>
                                                }
                                            </mat-optgroup>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                @if (isShowStageMessage) {
                                <div class="stage-message">
                                    Please, check for whom you want to book the meeting
                                </div>
                                }
                            </div>
                        </div>
                        @if (isShowOwner) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Owner
                            </div>
                            <div class="col-10 owner">
                                <mat-form-field class="owner-select-global">
                                    <mat-select [(ngModel)]="dealsPerson.user_id" (selectionChange)="updateOwner()"
                                        [disabled]="isOwnerSelectDisabled" panelClass="owner-panel-class-inject">
                                        @for (item of filteredUsers; track item) {
                                        <mat-option [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>

                                @if(isAllowToChangeOwnerOfSomeoneElseDeal)
                                {
                                <button class="btn btn-success waves-light move-this-lead-to-me-button"
                                    (click)="onMoveThisLeadToMe();">Move this lead
                                    to me</button>
                                }
                            </div>
                        </div>
                        }
                        <div class="row hover">
                            <div class="col-2 text">
                                Gender
                            </div>
                            <div class="col-10 gender">
                                <mat-form-field class="gender-select-global">
                                    <mat-select [(value)]="gender" [disabled]="!isAdmin && !isMyDeal">
                                        @for (gender of genderList; track gender) {
                                        <mat-option [value]="gender" (click)="updateGender(item.id, gender)">{{gender}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row hover">
                            <div class="col-2 text">
                                Group
                            </div>
                            @if (pipelineId === STAGES_AND_PIPELINES.ClientsPipeline.id) {
                            <div class="col-10">
                                <mat-form-field>
                                    <mat-select [(value)]="dealsPerson.clientGroupId"
                                        [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                                        (selectionChange)="updateClientGroup($event.value)"
                                        (keydown)="$event.stopPropagation()">
                                        @for (group of groupList; track group) {
                                        <mat-option [value]="group.id">
                                            {{group.name}}
                                        </mat-option>
                                        }
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            }
                        </div>

                        @if (isClient) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Client Rating
                            </div>
                            @if (dealsPerson.clientRating) {
                            <div class="col-10">
                                {{ dealsPerson.clientRating }}
                            </div>
                            }
                        </div>
                        }

                        @if (isClient && (currentUser.role_id === RolesEnum.MasterLead
                        || currentUser.role_id === RolesEnum.SystemAccount
                        || currentUser.role_id === RolesEnum.EA
                        || currentUser.role_id === RolesEnum.EaCaller
                        || currentUser.role_id === RolesEnum.Admin
                        || (isMyDeal && currentUser.role_id === RolesEnum.Clothier))) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Lifetime Spend
                            </div>
                            <div class="col-10">
                                ${{ dealsPerson.client_lifetime_spend | money }}
                            </div>
                        </div>
                        }

                        @if (currentUser.role_id === RolesEnum.MasterLead || currentUser.isAllowedExportLeadDate) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Last Export Date
                            </div>
                            <div class="col-10">
                                {{ dealsPerson.last_export_deal_date | date :'dd-MMM-yyyy h:mm a'}}
                            </div>
                        </div>
                        }

                        @if (isNeedTimezone) {
                        <div class="row hover">
                            <div class="col-2 text">
                                Local timezone
                            </div>
                            <div class="col-10">
                                {{ dealsPerson.timeZone }}
                            </div>
                        </div>
                        }

                        <div class="row hover">
                            <div class="col-2 text">
                                Partner First Name
                            </div>
                            @if (item.partnerFirstName && !addPartnerFirstNameValue) {
                            <div class="col-10 notes">
                                {{item.partnerFirstName}}
                                @if (isAdmin || isMyDeal || isAllowedFieldsChange) {
                                <div class="edit-button"
                                    (click)="changeStatus(personPropertiesToUpdate.PartnerFirstName)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>}
                            </div>
                            }
                            @if (addPartnerFirstNameValue) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.partnerFirstName}}" #textPartnerFirstName>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.PartnerFirstName)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.PartnerFirstName, textPartnerFirstName.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if (!item.partnerFirstName && !addPartnerFirstNameValue && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange)) {
                            <div class="col-8 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.PartnerFirstName)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>

                        <div class="row hover">
                            <div class="col-2 text">
                                Partner Last Name
                            </div>
                            @if (item.partnerLastName && !addPartnerLastNameValue) {
                            <div class="col-10 notes">
                                {{item.partnerLastName}}
                                @if (isAdmin || isMyDeal || isAllowedFieldsChange) {
                                <div class="edit-button"
                                    (click)="changeStatus(personPropertiesToUpdate.PartnerLastName)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if (addPartnerLastNameValue) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.partnerLastName}}" #textPartnerLastName>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.PartnerLastName)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.PartnerLastName, textPartnerLastName.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if (!item.partnerLastName && !addPartnerLastNameValue && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange)) {
                            <div class="col-8 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.PartnerLastName)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover">
                            <div class="col-2 text">
                                Partner Email
                            </div>
                            @if (item.partnerEmail && !addPartnerEmailValue) {
                            <div class="col-10 notes">
                                {{item.partnerEmail}}
                                @if (isAdmin || isMyDeal || isAllowedFieldsChange) {
                                <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.PartnerEmail)">
                                    <button type="button" class="open-edit">
                                        <mat-icon>mode_edit</mat-icon>
                                    </button>
                                </div>
                                }
                            </div>
                            }
                            @if (addPartnerEmailValue) {
                            <div class="col-6 editInProgress">
                                <div class="edit-input">
                                    <input type="text" value="{{item.partnerEmail}}" #textPartnerEmail>
                                </div>
                                <div class="edit-buttons">
                                    <button class="cancel"
                                        (click)="changeStatus(personPropertiesToUpdate.PartnerEmail)">Cancel</button>
                                    <button class="save"
                                        (click)="updateInformation(item.id, personPropertiesToUpdate.PartnerEmail, textPartnerEmail.value)">Save</button>
                                </div>
                            </div>
                            }
                            @if (!item.partnerEmail && !addPartnerEmailValue && (isAdmin || isMyDeal ||
                            isAllowedFieldsChange)) {
                            <div class="col-8 withoutNotes">
                                <div class="add-notes-value"
                                    (click)="changeStatus(personPropertiesToUpdate.PartnerEmail)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover phone phone-emails">
                            <div class="col-2 text">
                                Partner Phone
                            </div>
                            @if (partnerPhoneArray.length > 0 && partnerPhoneArray[0].value !== '' && !addPartnerPhoneValue) {
                            <div class="col-10 notes">
                                    <div class="col-10 notes">
                                        {{ partnerPhoneArray[0].value }}
                                        @if (partnerPhoneArray.length > 0 && isAdmin || isMyDeal || isAllowedFieldsChange) {
                                        <div class="edit-button" (click)="changeStatus(personPropertiesToUpdate.PartnerPhone)">
                                            <button type="button" class="open-edit">
                                                <mat-icon>mode_edit</mat-icon>
                                            </button>
                                        </div>
                                        }                                    
                                    </div>
                            </div>
                            }

                            @if (addPartnerPhoneValue) {
                            <div class="col-10 editInProgress">
                                <pd-field-intl-tel
                                    [phones]="partnerPhoneArray"
                                    [isSinglePhone]= "true"
                                    (phonesSave)="updatePersonContactsInfo(item.id, personPropertiesToUpdate.PartnerPhone, $event)"
                                    (phonesCancel)="partnerPhonesCancel()">
                                </pd-field-intl-tel>
                            </div>
                            }

                            @if (partnerPhoneArray.length > 0 && partnerPhoneArray[0].value === '' && !addPartnerPhoneValue && (isAdmin ||
                            isMyDeal)) {
                            <div class="col-10 withoutNotes">
                                <div class="add-notes-value" (click)="changeStatus(personPropertiesToUpdate.PartnerPhone)">
                                    <span class="add-value">+Add value</span>
                                </div>
                            </div>
                            }
                        </div>
                        <div class="row hover phone phone-emails">
                            <div class="col-2 text">
                               Links
                            </div>
                            <div class="col-10 editInProgress">
                                <pd-third-party-links [dealId]='id' [isMyDeal]='isMyDeal'>
                                </pd-third-party-links>
                            </div>
                        </div>
                        <!-- @if (item.partnerToken && true) { //TODO:PD-2386
                        <div class="row hover">
                            <div class="col-2 text">
                                Partner Info Link
                            </div>
                            <div class="col-10 notes">
                                <a class="btn btn-primary waves-light hotlist" style="margin-left: 0;" [text-copy]="getPersonInfoUrl()" (click)="tooltip.show();$event.stopPropagation()"
                                #tooltip="matTooltip" matTooltip="Copied!" (mouseenter)="$event.stopImmediatePropagation()"
                                matTooltipHideDelay="100">Copy link</a>
                            </div>
                        </div>
                        } -->
                    </div>

                    <div class="col-1 custom-card-body">
                        <button class="copy-button" [text-copy]="item.firstName + ' ' + item.lastName">
                            <img src="../../assets/copy-full-name.png" bootstrapTooltip="copy full name"
                                placement="bottom">
                        </button>
                    </div>
                </div>

            </div>
            <div class="col-lg-12 col-xl-4">
                <div class="row hover">
                    @if ((((dealsPerson.stage_id === ALL_STAGES.OrderShipped && currentUser.role_id !== RolesEnum.EA &&
                    currentUser.role_id !== RolesEnum.EaCaller)) && dealsPerson.isRotting)) {
                    <div>
                        <button class="increase-rotting-time-button check-is-not-use"
                            [disabled]="(dealsPerson.increasingRottenTimeCounter && dealsPerson.increasingRottenTimeCounter >= 3) || !(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                            (click)="increaseRottingTime();">Postpone</button>
                    </div>
                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.Reschedule && dealsPerson.isRotting) &&
                    (currentUser.role_id === RolesEnum.Clothier || currentUser.role_id === RolesEnum.Admin ||
                    currentUser.role_id === RolesEnum.MasterLead)) {
                    <div>
                        <button class="increase-rotting-time-button check-is-not-use"
                            [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                            (click)="reselectRottingTimeReschedule();">Reselect date</button>
                    </div>
                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.InAlterations && dealsPerson.isRotting) &&
                    (currentUser.role_id === RolesEnum.Clothier || currentUser.role_id === RolesEnum.Admin ||
                    currentUser.role_id === RolesEnum.MasterLead)) {
                    <div>
                        <button class="increase-rotting-time-button check-is-not-use"
                            [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                            (click)="reselectRottingTimeInAlteration();">Reselect Delivery date</button>
                    </div>
                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.DeliveryMeeting && dealsPerson.isRotting) &&
                    (currentUser.role_id === RolesEnum.Clothier || currentUser.role_id === RolesEnum.Admin ||
                    currentUser.role_id === RolesEnum.MasterLead)) {
                    <div>
                        <button class="increase-rotting-time-button check-is-not-use"
                            [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                            (click)="reselectRottingTimeDeliveryMeeting();">Reselect Delivery
                            date</button>
                    </div>
                    }
                </div>
                <div class="hover">
                    @if ((dealsPerson.stage_id === ALL_STAGES.Meeting || isClient) && dealsPerson.files_count > 0) {

                    <button class="btn btn-primary waves-light hotlist"
                        (click)="openHotlist(dealsPerson.id, dealsPerson.name)">Hotlist</button>

                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.Meeting || isClient) && dealsPerson.files_count_pdf > 0) {

                    <button class="btn btn-primary waves-light hotlist"
                        (click)="openHotlistPdf(dealsPerson.id);$event.stopPropagation()">Hotlist 2.0</button>

                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.Meeting || isClient)) {

                    <button class="btn btn-primary waves-light hotlist"
                        (click)="exportWardrobePlan(dealsPerson.id, dealsPerson.name);$event.stopPropagation()">
                        @if (dealsPerson.wardrobe_image_count > 0) {
                        <span>&#10003;</span>
                        } Wardrobe Plan
                    </button>

                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.Meeting || isClient)) {

                    <button class="btn btn-primary waves-light hotlist"
                        (click)="uploadWardrobePlanModal(dealsPerson);$event.stopPropagation()">Upload Wardrobe
                        Plan</button>

                    }
                    @if ((dealsPerson.stage_id === ALL_STAGES.Meeting || isClient)) {

                    <button class="btn btn-primary waves-light hotlist"
                        (click)="openMeetingPrepPdf(dealsPerson);$event.stopPropagation()">Print Meeting
                        Prep</button>

                    }
                </div>
                <div class="hover">
                    <div class="col-12">
                        @if (dealsPerson.stage_id === ALL_STAGES.NewOrder &&
                        (currentUser.is_admin || currentUser.role_id === RolesEnum.EA || currentUser.role_id ===
                        RolesEnum.EaCaller || currentUser.role_id === RolesEnum.MasterLead)) {
                        <div class="checkbox">
                            <mat-checkbox #checkCalled
                                [disabled]="(this.dealsPerson.called) || !(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                                (change)="changeCalled()" [checked]="dealsPerson.called">Called</mat-checkbox>
                        </div>
                        }
                        @if ((dealsPerson.stage_id === ALL_STAGES.Less3Weeks
                        || dealsPerson.stage_id === ALL_STAGES.Less6Weeks
                        || dealsPerson.stage_id === ALL_STAGES.Less9Weeks
                        || dealsPerson.stage_id === ALL_STAGES.OverdueLess12Weeks
                        || dealsPerson.stage_id === ALL_STAGES.OverdueLess16Weeks)
                        && (currentUser.is_admin
                        || currentUser.role_id === RolesEnum.EA
                        || currentUser.role_id === RolesEnum.EaCaller
                        || currentUser.role_id === RolesEnum.MasterLead)) {
                        <div class="checkbox">
                            <mat-checkbox #checkIsOpenOrderNotified [disabled]="(dealsPerson.isOpenOrderNotified || !dealsPerson.isRotting) 
                                                            || !(isAdmin || isMyDeal || isAllowedFieldsChange)"
                                (change)="changeIsOpenOrderNotified()"
                                [(ngModel)]="dealsPerson.isOpenOrderNotified">{{DealHelper.getEaFollowUpTypeLabel(dealsPerson.stage_id)}}</mat-checkbox>
                        </div>
                        }
                        @if (isClient && (currentUser.role_id === RolesEnum.Clothier
                        || currentUser.role_id === RolesEnum.Admin
                        || currentUser.role_id === RolesEnum.MasterLead
                        || currentUser.role_id === RolesEnum.EA
                        || currentUser.role_id === RolesEnum.EaCaller
                        )) {
                        <div class="checkbox">
                            <mat-checkbox [disabled]="!(currentUser.role_id === RolesEnum.MasterLead 
                                                                || currentUser.role_id === RolesEnum.Admin  
                                                                || currentUser.role_id === RolesEnum.EA  
                                                                || currentUser.role_id === RolesEnum.EaCaller  
                                                                || isMyDeal) 
                                                                || dealsPerson.stage_id == ALL_STAGES.Kill 
                                                                || dealsPerson.stage_id == ALL_STAGES.KilledRecord"
                                (change)="changePreferToContactByEmail()"
                                [checked]="dealsPerson.preferToContactByEmail">Prefer To Contact
                                By Email
                            </mat-checkbox>
                        </div>
                        }
                        @if (dealsPerson.stage_id === ALL_STAGES.DeliveryCheck &&
                        (currentUser.is_admin || currentUser.role_id === RolesEnum.EA || currentUser.role_id ===
                        RolesEnum.EaCaller || currentUser.role_id === RolesEnum.MasterLead)) {
                        <div class="checkbox">
                            <mat-checkbox #checkIsDeliveryEmailSent
                                [disabled]="this.dealsPerson.isDeliveryEmailSent || !(isAdmin || isMyDeal || isAllowedFieldsChange === true)"
                                (change)="changeIsDeliveryEmailSent()"
                                [(ngModel)]="dealsPerson.isDeliveryEmailSent">Email sent</mat-checkbox>
                        </div>
                        }
                        @if (isClient && (currentUser.is_admin === true && currentUser.role_id !== RolesEnum.EA &&
                        currentUser.role_id !== RolesEnum.EaCaller)) {
                        <div class="checkbox">
                            <mat-checkbox [(ngModel)]="dealsPerson.isForClothier"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                            || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord"
                                (change)="onIsForClothierChanged($event.checked)">
                                For Clothier Only</mat-checkbox>
                        </div>
                        }
                        @if (isClient && currentUser.role_id === RolesEnum.MasterLead) {
                        <div class="checkbox">
                            <mat-checkbox [(ngModel)]="dealsPerson.isIncreasedRecentlyContactedRotting"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) ||
                                 dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" (change)="onRottingIncreasingChanged($event.checked)">Increased
                                Recently Contacted
                                Rotting</mat-checkbox>
                        </div>
                        }
                        @if (((dealsPerson.stage_id === ALL_STAGES.Less3Weeks
                        || dealsPerson.stage_id === ALL_STAGES.Less6Weeks
                        || dealsPerson.stage_id === ALL_STAGES.Less9Weeks
                        || dealsPerson.stage_id === ALL_STAGES.OverdueLess12Weeks
                        || dealsPerson.stage_id === ALL_STAGES.OverdueLess16Weeks
                        || dealsPerson.stage_id === ALL_STAGES.OverdueLess24Weeks
                        || dealsPerson.stage_id === ALL_STAGES.WriteOff) && (
                        currentUser.role_id === RolesEnum.MasterLead
                        || currentUser.role_id === RolesEnum.Admin
                        || currentUser.role_id === RolesEnum.Clothier
                        || currentUser.role_id === RolesEnum.EA))) {
                        <div class="checkbox">
                            <mat-checkbox [(ngModel)]="dealsPerson.isOrderShipped" (change)="onIsOrderShippedChanged()"
                                [disabled]="dealsPerson.isOrderShipped || currentUser.role_id === RolesEnum.Clothier 
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord">Order
                                Shipped
                            </mat-checkbox>
                        </div>
                        }
                        @if (isClient) {
                        <div class="checkbox">
                            <mat-checkbox #check (change)="checkIsNotUse()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord"
                                [checked]="isNotUseAsCommonConnection">Don't
                                Use This Name as a Connection</mat-checkbox>
                        </div>
                        }
                        @if (isClient) {
                        <div class="checkbox">
                            <mat-checkbox #check (change)="checkIsDontSendPromotionalEmails()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" [checked]="isDontSendPromotionalEmails">Don't Send
                                Promotional Emails</mat-checkbox>
                        </div>
                        }
                        @if (isClient) {
                            <div class="checkbox">
                                <mat-checkbox #check (change)="checkIsDontSendPartnerEmails()"
                                    [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                    || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" [checked]="isDontSendPartnerEmails">Don't Send
                                    Partner Emails</mat-checkbox>
                            </div>
                            }
                        @if (isClient) {
                        <div class="checkbox">
                            <mat-checkbox #check (change)="checkIsDontSendWhatsapp()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" [checked]="isDontSendWhatsapp">Don't message
                                on Whatsapp</mat-checkbox>
                        </div>
                        }
                        @if (isClient) {
                        <div class="checkbox">
                            <mat-checkbox #check (change)="checkIsDontSendSms()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" [checked]="isDontSendSms">Don't send
                                SMS</mat-checkbox>
                        </div>
                        }
                        @if (isClient) {
                        <div class="checkbox">
                            <mat-checkbox #check (change)="checkIsPartnerRepliedToBDayEmail()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) 
                                    || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord" [checked]="isPartnerRepliedToBDayEmail">Partner
                                Replied To Birthday Email</mat-checkbox>
                        </div>
                        }
                        @if (dealsPerson.stage_id === ALL_STAGES.NewOrder
                        && (currentUser.is_admin || currentUser.role_id === RolesEnum.EA || currentUser.role_id ===
                        RolesEnum.EaCaller || currentUser.role_id === RolesEnum.MasterLead)) {
                        <div class="checkbox">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                [disabled]="!(isAdmin || isMyDeal || isAllowedFieldsChange === true) || (!this.dealsPerson.called)
                                || dealsPerson.stage_id == ALL_STAGES.Kill || dealsPerson.stage_id == ALL_STAGES.KilledRecord"
                                (change)="updateStage(ALL_STAGES.DraftOrder)">
                                Draft Ready</mat-checkbox>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="custom-card-body">
                    @if (dealsPerson !== undefined) {
                    <div class="row hover">
                        <div #notesTitle class="col-1 text">
                            Notes
                        </div>
                        <pd-note-table #notesTable [dealId]='id' [isMyDeal]='isMyDeal' class="col-11">
                        </pd-note-table>
                    </div>
                    }
                    @if (!isClient && participants && participants.dealParticipants &&
                    participants.dealParticipants.length > 0) {
                    <div class="row">
                        <div class="col-1 text">
                            Participant
                        </div>
                        <div class="col-11 participants-table">
                            <table class="table table-sm table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="width: 25%;">Name</th>
                                        <th style="width: 25%;">Company</th>
                                        <th style="width: 25%;">Position</th>
                                        <th style="width: 25%;">Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (participant of participants.dealParticipants; track participant) {
                                    <tr>
                                        <td>{{participant.isOpenedForCurrentUser ? participant.name :
                                            hideClientNameText}}
                                        </td>
                                        <td>{{participant.company}}</td>
                                        <td>{{participant.position}}</td>
                                        <td>{{participant.location}}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }

                    @if (sameBuildings && sameBuildings.dealSameBuildings && sameBuildings.dealSameBuildings.length > 0)
                    {
                    <div class="row">
                        <div class="col-1 text">
                            Clients in the Same Building
                        </div>
                        <div class="col-11 participants-table">
                            <table class="table table-sm table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th style="width: 10%;">Name</th>
                                        <th style="width: 20%;">Company</th>
                                        <th style="width: 20%;">Position</th>
                                        <th style="width: 20%;">Location</th>
                                        <th style="width: 30%;">Address</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (sameBuilding of sameBuildings.dealSameBuildings; track sameBuilding) {
                                    <tr>
                                        <td>{{sameBuilding.isOpenedForCurrentUser ? sameBuilding.name :
                                            hideClientNameText}}
                                        </td>
                                        <td>{{sameBuilding.company}}</td>
                                        <td>{{sameBuilding.position}}</td>
                                        <td>{{sameBuilding.location}}</td>
                                        <td>{{sameBuilding.address}}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }

                    <div class="row mt-2">
                        <div class="col-1 text">
                            CMS Info
                        </div>
                        <div class="col-11">
                            @if (!cmsClient) {
                            <span class="text-center">
                                <strong>N/A</strong>
                            </span>
                            }
                            @if (cmsClient) {
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 25%;">Client ID</th>
                                        <th scope="col" style="width: 25%;">Full Name</th>
                                        <th scope="col" style="width: 25%;">Company</th>
                                        <th scope="col" style="width: 25%;">Linked Location</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{{cmsClient.id}}</td>
                                        <td>{{cmsClient.completeName}}</td>
                                        <td>{{cmsClient.company}}</td>
                                        <td>{{cmsClient.linkedinLocation}}</td>
                                    </tr>
                                </tbody>
                            </table>
                            }
                        </div>
                    </div>

                    @if ((currentUser.role_id === RolesEnum.MasterLead
                    || currentUser.role_id === RolesEnum.Admin
                    || currentUser.role_id === RolesEnum.EA
                    || currentUser.role_id === RolesEnum.Clothier)
                    && isClient) {
                    <div class="row mt-2">
                        <div class="col-1 text">
                            EA follow-up screenshots
                        </div>
                        <pd-ea-follow-up #eaFollowUpTable [dealId]='id' (edit)="updateEaFollowUpImage($event)"
                            class="col-11">
                        </pd-ea-follow-up>
                    </div>
                    }

                    @if (dealHistory && dealHistory.length > 0) {
                    <div class="row mt-2">
                        <div class="col-1 text">
                            Deal History
                        </div>
                        <div class="col-11 participants-table">
                            <table class="table table-sm table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Action Time</th>
                                        <th>Stage</th>
                                        <th>Pipeline</th>
                                        <th>Owner</th>
                                        <th>Rotten Time</th>
                                        <th>Who Modified</th>
                                        <th>History Note</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    @for (historyItem of dealHistory; track historyItem) {
                                    <tr>
                                        <td>{{dealsPerson.name}}</td>
                                        <td>{{historyItem.actionTime | date :'dd-MMM-yyyy h:mm a'}}</td>
                                        <td>{{historyItem.stageName}}</td>
                                        <td>{{historyItem.pipelineName}}</td>
                                        <td>{{historyItem.ownerName}}</td>
                                        <td>{{historyItem.rottenTime | date :'dd-MMM-yyyy h:mm a'}}</td>
                                        <td>{{historyItem.modifiedByName}}</td>
                                        <td>{{historyItem.modifiedNote}}</td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    }

                </div>
            </div>
        </div>
        }
    </div>
    }
    @if (tabs[1].isActive) {
    <div>
        <div>
            <pd-client-card #clientCard [dealId]='id' [dealTitle]="item.firstName + ' ' + item.lastName"
                [genderId]="getGenderId(gender)"></pd-client-card>
        </div>
    </div>
    }
    @if (tabs[3].isActive) {
    <div>
        <bing-map [dealId]="dealsPerson.id"></bing-map>
    </div>
    }
    @if (tabs[4].isActive) {
    <div>
        @if (item.phone && item.phone.length > 0 && item.phone[0].value) {
        <div>
            <chat [dealId]='id' [dealTitle]="item.firstName + ' ' + item.lastName"
                [isDontWhatsappMessage]="isDontSendWhatsapp" [isDontSmsMessage]="isDontSendSms"
                [isCandidateConversation]="isCandidate"></chat>
        </div>
        }
    </div>
    }

    }
    @if (currentUser.role_id === RolesEnum.SystemAccount && dealsPerson && item) {
    <div class="closeDeal" style="padding: 10px">
        <button class="btn btn-danger float-right" (click)="deleteDeal(id);">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    }
</div>
}

<pd-custom-referral-modal #referralModal (saveWhoRef)="saveWhoReferred($event)" (closeModal)="closeReferralModal()">
</pd-custom-referral-modal>
<pd-custom-kill-record-modal #killRecordModal [dialogOptions]="killRecordOptions"
    [isInputDisplayed]="currentUser?.is_admin == true" (cancelBtn)="cancelMoveRecord()">
</pd-custom-kill-record-modal>
<pd-custom-kill-record-modal #isIncreasedRottingModal [isInputDisplayed]="false"
    [dialogOptions]="isIncreasedRottingOptions" (cancelBtn)="dealsPerson.isIncreasedRecentlyContactedRotting = false;"
    (confirmBtn)="onRottingIncreasingConfirmed()"></pd-custom-kill-record-modal>

<pd-custom-kill-record-modal #isForClothierModal [isInputDisplayed]="false" [dialogOptions]="isForClothierOptions"
    (cancelBtn)="dealsPerson.isForClothier = false;" (confirmBtn)="onIsForClothierConfirmed()">
</pd-custom-kill-record-modal>
<pd-custom-kill-record-modal #isForClothierCancelModal [isInputDisplayed]="false"
    [dialogOptions]="isForClothierCancelOptions" (cancelBtn)="dealsPerson.isForClothier = true;"
    (confirmBtn)="onIsForClothierCanceled()"></pd-custom-kill-record-modal>
<pd-custom-kill-record-modal #trackingLinkModal [isUrl]="true" [inputPlaceholder]="trackingLinkPlaceholder"
    [isInputDisplayed]="true" [dialogOptions]="trackingLinkOptions" (cancelBtn)="onTrackingLinkCanceled()"
    (confirmBtn)="onTrackingLinkConfirmed()"></pd-custom-kill-record-modal>

<pd-custom-meeting-date #meetingDateModal (closeModal)="closeDateMeetingModal()" (saveDate)="saveDateOfMeeting($event)">
</pd-custom-meeting-date>

<pd-custom-rotten-time-modal #rottenTimeModal (closeModal)="closeCustomDateModal()" (saveDate)="saveCustomDate($event)">
</pd-custom-rotten-time-modal>
<pd-wardrobe-plan #wardrobePlan></pd-wardrobe-plan>
<pd-meeting-prep #meetingPrep></pd-meeting-prep>
<pd-custom-wardrobe-plan-upload-modal #uploadWardrobePlan></pd-custom-wardrobe-plan-upload-modal>
<pd-custom-ea-follow-up-image-upload-modal #eaFollowUpImageUpload>
</pd-custom-ea-follow-up-image-upload-modal>