<div class="chat-selector">
    @if (isGlobalChat) {
    <div class="chat-filter-wrapper">
        <div class="chat-filter">
            <input [(ngModel)]="searchText" placeholder="Search chats..." />
            @if(!isRecruiterAccount)
            {
            <mat-form-field class="conversation-type-select conversation-type-select-global">
                <mat-select [(value)]="selectedConversationType">
                    @for (conversationType of conversationTypeList; track conversationType) {
                    <mat-option [value]="conversationType">{{conversationType}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
            }
        </div>
    </div>
    }
    <div
        [ngClass]="{'chat-list-wrapper': !isGlobalChat, 'chat-list-wrapper--global': isGlobalChat && !isRecruiterAccount, 'chat-list-wrapper--recruiter': isRecruiterAccount}">
        <ul class="chat-list">
            @for (chat of filteredChatList; track chat) {
            <li [class.active-chat]="chat && activeChat && chat.id === activeChat.id" (click)="setActiveChat(chat)">
                <div class="chat-info">
                    <div class="name">
                        @if (!chat.isWhatsAppConversation) {
                        <img src='../../assets/sms.png' class="chat-type-icon" />
                        }
                        @if (chat.isWhatsAppConversation) {
                        <img src='../../assets/whatsapp.png' class="chat-type-icon" />
                        }
                        {{ chat.name ? chat.name : chat.chatPhone}}
                    </div>
                    @if (chat.name) {
                    <div class="number">{{ chat.chatPhone }}</div>
                    }
                    @if (chat.lastMessage) {
                    <div class="text">
                        @if (chat.lastMessage.isClientMessage) {
                        <span class="client-label">Client: </span>
                        }
                        @if (chat.lastMessage.medias && chat.lastMessage.medias.length > 0) {
                        <div class="media-preview">
                            @for (media of chat.lastMessage.medias; track media) {

                            @if (FileHelper.isVideo(media.contentType)) {
                            <div>Video</div>
                            }
                            @if (FileHelper.isAudio(media.contentType)) {
                            <div>Audio</div>
                            }
                            @if (FileHelper.isImage(media.contentType)) {
                            <div>Image</div>
                            }

                            }
                        </div>
                        }
                        @if(TwilioHelper.isReactionMessage(chat.lastMessage))
                        {
                        <i>{{TwilioHelper.textForReactionMessage}}</i>
                        }
                        @if(!TwilioHelper.isReactionMessage(chat.lastMessage))
                        {
                        {{chat.lastMessage.text}}
                        }
                    </div>
                    }
                    @if (chat.lastMessage) {
                    <div>
                        <div class="time">{{DateHelper.utcStringToLocalDate(chat.lastMessage.time) | date : dateFormat}}
                        </div>
                        @if (!chat.lastMessage.isClientMessage) {
                        <div class="icon-wrapper" bootstrapTooltip={{getErrorMessageTooltip(chat.lastMessage)}}>
                            <mat-icon [svgIcon]="TwilioHelper.getMessageIcon(chat.lastMessage)"
                                [ngClass]="TwilioHelper.getMessageIconClass(chat.lastMessage)" class="icon">
                            </mat-icon>
                        </div>
                        }
                    </div>
                    }
                </div>
            </li>
            }
        </ul>
    </div>
</div>