import { Email, Phone } from "./Deal";

export class DealDetails {
    public id: number = 0;
    public name: string = "";
    public firstName: string = "";
    public lastName: string ="";
    public birthday?: Date = null; 
    public company: string = "";
    public companyId: any = null
    public companyAddress: string = "";
    public homeAddress: string = "";
    public position: string = "";
    public positionId = 0;
    public phone: Phone[] = null;
    public email: Email[] = null;
    public linkedin_URL: string = "";
    public stage_id: number = 0;
    public previous_labeled_stage_id?: number;
    public files_count: number = 0;
    public files_count_pdf: number = 0;
    public wardrobe_image_count: number = 0;
    public client_lifetime_spend: number = 0;
    public last_export_deal_date?: Date = null;
    public personId: number = 0;
    public personWhoReferred: string = "";
    public personWhoReferredId: number = 0;
    public clientId: number;
    public meetingDateUtc: Date;

    public pipeline_id: number = 0;
    public user_id: number = 0;

    public location: string = "";
    public locationId: any = null;

    public genderId: number = 0;
    public clientGroupId: number = null;
	
    public isNotUseAsCommonConnection: boolean = false;
    public isDontSendPromotionalEmails: boolean = false;
    public isDontSendPartnerEmails: boolean = false;
    public isDontSendWhatsapp: boolean = false;
    public isDontSendSms: boolean = false;
    public isPartnerRepliedToBDayEmail: boolean = false;
    public called: boolean = false;
    public dateOfCall?: Date = null;
    public isOpenOrderNotified: boolean = false;
    public isDeliveryEmailSent: boolean = false;
    public isIncreasedRecentlyContactedRotting?: boolean = false;
    public isForClothier?: boolean = false;
    public isOrderShipped?: boolean = false;
    public bookedById?: number = null;
    public bookedByName: string = null;
    public trackingLink: string = "";
    public preferToContactByEmail: boolean = false;
    public clientRating: string = "";
    public timeZone: string;
    public increasingRottenTimeCounter?: number;
    public maxIncreasingRottenTimeCounter?: number;
    public reselectRottenTimeCounter?: number;
    public isRotting = false;

    public latitude: string = null;
    public longitude: string = null;
    
    public partnerFirstName: string = "";
    public partnerLastName: string = "";
    public partnerEmail: string = "";
    public partnerPhone: string = "";
    public partnerToken: string = "";
}
