import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Chat } from "src/app/core/models/chat-models/Chat";
import { DateHelper } from "src/app/core/utils/date.helper";
import { FileUtil } from "src/app/core/utils/file.util";
import { Message } from "src/app/core/models/chat-models/Message";
import { UserContextService } from "src/app/core/services/user-context.service";
import { RolesEnum } from "src/app/core/enums/RolesEnum";
import { User } from "src/app/core/models/UserModel";
import { TwilioHelper } from "src/app/core/utils/twilio.helper";

@Component({
  selector: 'chat-selector',
  templateUrl: './chat-selector.component.html',
  styleUrls: ['./chat-selector.component.scss'],
})

export class ChatSelectorComponent implements OnInit {
  @Output() public onChangeChat = new EventEmitter<Chat>();
  @Input() public chatList: Chat[] = [];
  @Input() public isGlobalChat: boolean = false;

  public searchText: string = '';
  public dateFormat = 'dd-MMM-yyyy h:mm a';

  public TwilioHelper = TwilioHelper;
  public DateHelper = DateHelper;
  public FileHelper = FileUtil;

  public activeChat?: Chat;

  public conversationTypeList = ['All', 'Clients', 'Candidates'];
  public selectedConversationType = 'Clients';

  public isRecruiterAccount: boolean = false;

  private currentUser: User;

  constructor(private userContextService: UserContextService,
  ) {
    this.currentUser = this.userContextService.user.value;
    this.isRecruiterAccount = this.currentUser.role_id === RolesEnum.Recruiter;

    if (this.isRecruiterAccount) {
      this.selectedConversationType = 'Candidates';
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    if (this.filteredChatList.length > 0) {
      this.setActiveChat(this.filteredChatList[0]);
    }
  }

  public setActiveChat(chat: Chat) {
    this.activeChat = chat;
    this.onChangeChat.emit(chat);
  }

  public get filteredChatList(): Chat[] {
    if (!this.searchText) {
      return this.filterChatListByConversationType(this.chatList);
    }

    const searchTextLower = this.searchText.replace(/\s/g, '').toLowerCase();
    const searchTextNumeric = this.searchText.replace(/[^\d+]/g, '');

    return this.filterChatListByConversationType(this.chatList.filter(chat => {
      const name = chat.name ? chat.name.replace(/\s/g, '').toLowerCase() : '';
      const phone = chat.chatPhone.toLowerCase();
      return name.includes(searchTextLower)
        || phone.includes(searchTextLower)
        || (searchTextNumeric && phone.includes(searchTextNumeric));
    }));
  }

  private filterChatListByConversationType(chatList: Chat[]) {
    if (this.isGlobalChat) {
      if (this.selectedConversationType === 'Clients') {
        return chatList?.filter(chat => !chat.isRecruiterConversation);
      } else if (this.selectedConversationType === 'Candidates') {
        return chatList?.filter(chat => chat.isRecruiterConversation);
      }
    }

    return chatList;
  }

  public getErrorMessageTooltip(message: Message) {
    return message.errorMessage;
  }
}