import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { CustomChipsSelectFilterComponent } from './custom/custom-chips-select-filter/pd-custom-chips-select-filter.component';
import { LocationChipsFilterComponent } from './filters/location-chips-filter.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips'
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { CustomSelectFilterWithoutLazyComponent } from './custom/custom-select-without-lazy/custom-select-without-lazy';
import { ModalComponent } from './modal/modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { WardrobePlanComponent } from './custom/wardrobe-plan/wardrobe-plan.component';
import { MeetingPrepComponent } from './custom/meeting-prep/meeting-prep.component';
import { CustomUploadWardrobePlanModalComponent } from './custom/custom-wardrobe-plan-upload-modal/custom-wardrobe-plan-upload-modal.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CustomSelectFilterComponent } from './custom/custom-select-filter/custom-select-filter.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { CustomRottenTimeModalComponent } from './custom/custom-rotten-time-modal/custom-rotten-time-modal.component';
import { CustomKillRecordModalComponent } from './custom/custom-kill-record-modal/custom-kill-record-modal.component';
import { CustomModalComponent } from './custom/custom-modal/custom-modal.component';
import { CustomReferralModalComponent } from './custom/custom-referral-modal/custom-referral-modal.component';
import { CustomUploadEaFollowUpImageModalComponent } from './custom/custom-ea-follow-up-image-upload-modal/custom-ea-follow-up-image-upload-modal.component';
import { CustomMeetingComponent } from './custom/custom-meeting/custom-meeting.component';
import { CustomMeetingDateModalComponent } from './custom/custom-meeting-date/custom-meeting-date.component';
import { CustomAddressConfirmComponent } from './custom/custom-address-confirm/custom-address-confirm.component';
import { CustomMeetingConfirmComponent } from './custom/custom-meeting-confirm/custom-meeting-confirm.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ExportDealsComponent } from './custom/export-deals/export-deals.component';
import { CustomContactsComponent } from './custom/custom-contacts/custom-contacts.component';
import { CustomNotesComponent } from './custom/custom-notes/custom-notes.component';
import { TextCopyDirective } from './directive/clipboard.directive';
import { PdCapitalizePipe } from './pipes/capitalize.pipe';
import { EnumToArrayPipe } from './pipes/enumToArray';
import { MasterLeadPipe } from './pipes/masterLeadStage.pipe';
import { MoneyPipe } from './pipes/money';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { OrderByNumberPipe } from './pipes/orderByNumber.pipe';
import { FilterPipe } from './pipes/search.pipe';
import { StagesForPipelinePipe } from './pipes/stagesForPipeline.pipe';
import { StagesToPipelinesPipe } from './pipes/stagesToPipelines.pipe';
import { TransferPopUpComponent } from './custom/transfer-pop-up/transfer-pop-up.component';
import { CustomExportModalComponent } from './custom/custom-export-modal/custom-export-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { ExportComponent } from './custom/export/export.component';
import { NoteComponent } from './notes/note-table.component';
import { MatSelectModule } from '@angular/material/select';
import { CustomCrownComponent } from './custom/custom-crown/custom-crown.component';
import { CustomSameBuildingComponent } from './custom/custom-same-building/custom-same-building.component';
import { TooltipDirective } from './directive/tooltip.directive';
import { MapComponent } from './custom/custom-map/custom-map.component';
import { MatSliderModule } from '@angular/material/slider';
import { ColorSelectorComponent } from './custom/color-selector/color-selector.component';
import { FabricSelectorComponent } from './custom/fabric-selectcor/fabric-selector.component';
import { AvatarBuilderComponent } from '../shared/avatar-builder/avatar-builder.component';
import { AvatarUploadHeadModalComponent } from '../shared/avatar-upload-head-modal/avatar-upload-head-modal.component';
import { AvatarBuilderCanvasComponent } from '../shared/avatar-builder-canvas/avatar-builder-canvas.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDialogModule } from '@angular/material/dialog';
import { CustomLinkModalComponent } from './custom/custom-link-modal/custom-link-modal.component';
import { QrCodeModalComponent } from './custom/custom-qr-code-modal/custom-qr-code-modal.component';
import { CustomClientCardModalComponent } from './custom/custom-client-card-modal/custom-client-card-modal.component';
import { AvatarDealSettingsComponent } from './avatar-deal-builder/avatar-deal-builder.component';
import { ClickOutsideDirective } from './directive/clickOutside.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomAchievementCardModalComponent } from './custom/custom-achievement-card-modal/custom-achievement-card-modal.component';
import { CustomSelectFilterGroupedDataComponent } from './custom/custom-select-grouped-data/custom-select-grouped-data';
import { AddValueComponent } from './field-landing/add-value/add-value.component';
import { ThirdPartyLinksComponent } from './third-party-links/third-party-links.component';

@NgModule({
    imports: [
        MatSliderModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        FormsModule,
        CoreModule,
        CommonModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FileUploadModule,
        TimepickerModule,
        ImageCropperModule
    ],
    declarations: [
        CustomChipsSelectFilterComponent,
        LocationChipsFilterComponent,
        CustomSelectFilterWithoutLazyComponent,
        ModalComponent,
        WardrobePlanComponent,
        MeetingPrepComponent,
        CustomUploadWardrobePlanModalComponent,
        CustomSelectFilterComponent,
        AlertModalComponent,
        CustomRottenTimeModalComponent,
        CustomKillRecordModalComponent,
        CustomModalComponent,
        CustomReferralModalComponent,
        CustomUploadEaFollowUpImageModalComponent,
        CustomMeetingComponent,
        CustomMeetingDateModalComponent,
        CustomAddressConfirmComponent,
        CustomMeetingConfirmComponent,
        ExportDealsComponent,
        CustomContactsComponent,
        CustomNotesComponent,
        TextCopyDirective,
        PdCapitalizePipe,
        EnumToArrayPipe,
        MasterLeadPipe,
        MoneyPipe,
        OrderByPipe,
        OrderByNumberPipe,
        FilterPipe,
        StagesForPipelinePipe,
        StagesToPipelinesPipe,
        TransferPopUpComponent,
        CustomExportModalComponent,
        CustomCrownComponent,
        ExportComponent,
        NoteComponent,
        CustomSameBuildingComponent,
        TooltipDirective,
        ClickOutsideDirective,
        ColorSelectorComponent,
        FabricSelectorComponent,
        AvatarBuilderComponent,
        AvatarUploadHeadModalComponent,
        AvatarBuilderCanvasComponent,
        MapComponent,
        AvatarBuilderComponent,
        CustomLinkModalComponent,
        QrCodeModalComponent,
        CustomClientCardModalComponent,
        AvatarDealSettingsComponent,
        CustomAchievementCardModalComponent,
        CustomSelectFilterGroupedDataComponent,
        AddValueComponent,
        ThirdPartyLinksComponent
    ],
    exports: [
        MatSliderModule,
        MatChipsModule,
        MatIconModule,
        MatListModule,
        MatTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatIconModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        MatExpansionModule,
        FormsModule,
        ReactiveFormsModule,

        CustomChipsSelectFilterComponent,
        LocationChipsFilterComponent,
        CustomSelectFilterWithoutLazyComponent,
        ModalComponent,
        WardrobePlanComponent,
        MeetingPrepComponent,
        CustomUploadWardrobePlanModalComponent,
        CustomSelectFilterComponent,
        AlertModalComponent,
        CustomRottenTimeModalComponent,
        CustomKillRecordModalComponent,
        CustomModalComponent,
        CustomReferralModalComponent,
        CustomUploadEaFollowUpImageModalComponent,
        CustomMeetingComponent,
        CustomMeetingDateModalComponent,
        CustomAddressConfirmComponent,
        CustomMeetingConfirmComponent,
        ExportDealsComponent,
        CustomContactsComponent,
        CustomNotesComponent,
        TextCopyDirective,
        PdCapitalizePipe,
        EnumToArrayPipe,
        MasterLeadPipe,
        MoneyPipe,
        OrderByPipe,
        OrderByNumberPipe,
        FilterPipe,
        StagesForPipelinePipe,
        StagesToPipelinesPipe,
        TransferPopUpComponent,
        CustomExportModalComponent,
        CustomCrownComponent,
        ExportComponent,
        NoteComponent,
        CustomSameBuildingComponent,
        TooltipDirective,
        ClickOutsideDirective,
        MapComponent,
        ColorSelectorComponent,
        FabricSelectorComponent,
        AvatarBuilderComponent,
        AvatarUploadHeadModalComponent,
        AvatarBuilderCanvasComponent,
        AvatarBuilderComponent,
        CustomLinkModalComponent,
        QrCodeModalComponent,
        CustomClientCardModalComponent,
        AvatarDealSettingsComponent,
        CustomAchievementCardModalComponent,
        CustomSelectFilterGroupedDataComponent,
        AddValueComponent,
        ThirdPartyLinksComponent
    ],
    providers: [
        MatDatepickerModule
    ],
})
export class SharedModule { }
