import { UserContextService } from '../../core/services/user-context.service';
import { UsersService } from '../../core/services/users.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReplaySubject, takeUntil } from 'rxjs';
import { User } from '../../core/models/UserModel';
import { RolesEnum } from '../../core/enums/RolesEnum';
import { CustomSelectFilterWithoutLazyComponent } from '../../shared/custom/custom-select-without-lazy/custom-select-without-lazy';
import { SocialMediaAdsTypes } from '../../core/enums/SocialMediaAdsTypes';
import { SocialMediaAdsLocationModel } from '../../core/models/SocialMediaAdsLocationModel';
import { SocialMediaAdsTokensService } from '../../core/services/social-media-ads-tokens.service';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from 'src/app/core/base.component';

@Component({
  selector: 'social-media-ads-tokens-list',
  templateUrl: './social-media-ads-tokens-list.component.html',
  styleUrls: ['./social-media-ads-tokens-list.component.scss']
})
export class SocialMediaAdsTokensListComponent extends BaseComponent implements OnInit {
  @ViewChild("locationFilter") public customSelectLocation: CustomSelectFilterWithoutLazyComponent;
  @ViewChild("userFilter") public customSelectUser: CustomSelectFilterWithoutLazyComponent;

  public usersLabel: string;
  public locationLabel: string;
  public spinner: boolean = false;
  public SocialMediaAdsTypes = SocialMediaAdsTypes;
  public adsSource: SocialMediaAdsTypes.Instagram;
  public isMasterLeadAccount: boolean = false;
  public tokens: any[] = [];
  public referenceLink: string;

  private currentUser: User;
  private currentLocation: SocialMediaAdsLocationModel;

  private filteredLocations = [];
  private filteredUserMultiSelect: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  private filteredLocationMultiSelect: ReplaySubject<SocialMediaAdsLocationModel[]> = new ReplaySubject<SocialMediaAdsLocationModel[]>(1);
  private filteredUsers = [];

  private filterUserIds = [];
  private allFilteredUsers = [];
  private allFilteredLocations = [];
  private filteredLocationIds = [];

  constructor(
    private usersService: UsersService,
    private userContextService: UserContextService,
    private socialMediaAdsTokenService: SocialMediaAdsTokensService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.spinner = true;
    this.initializeStore();
    await this.initializeData();
  }

  private async initializeData(): Promise<void> {
    const roles = [RolesEnum.Admin, RolesEnum.Clothier, RolesEnum.MasterLead];

    forkJoin({
      users: this.usersService.getUsers(),
      locations: this.socialMediaAdsTokenService.getLocations(),
      tokens: this.socialMediaAdsTokenService.getTokens()
    }).pipe(takeUntil(this.destroy$)).subscribe(({ users, locations, tokens }) => {
      this.usersLabel = 'Clothier';
      this.locationLabel = 'Select Location';
      this.allFilteredUsers = users.filter(x => roles.includes(x.role_id));
      this.filteredUsers = this.allFilteredUsers;
      this.filteredLocations = [...locations];
      this.allFilteredLocations = [...locations];
      this.tokens = tokens;
      this.getFilterData('user');
      this.getFilterData('location');
      this.adsSource = SocialMediaAdsTypes.Instagram;
      this.spinner = false;
    });
  }

  public async onSelectedUserItem(checked): Promise<void> {
    if (!checked) {
      this.getFilterData('user');
    }
    await this.loadData();
  }

  public async onSelectedLocationItem(checked): Promise<void> {
    if (!checked) {
      this.getFilterData('location');
    }
    await this.loadData();
  }

  public async closeSelectFilter(): Promise<void> {
    await this.loadData();
  }

  public getFilterData(filterType: string) {
    if (filterType === 'user') {
      this.filteredUsers = [];
      this.filteredUsers = this.filteredUsers.concat(this.allFilteredUsers);
      this.filteredUserMultiSelect.next(this.allFilteredUsers.slice());
      if (this.customSelectUser) {
        this.customSelectUser.setData(this.filteredUsers);
        if (this.customSelectUser.dataArray.map((u) => u.id).length == 0) {
          this.customSelectUser.selectItem(this.currentUser, { checked: true });
        }
      }
    } else if (filterType === 'location') {
      this.filteredLocations = [];
      this.filteredLocations = this.filteredLocations.concat(this.allFilteredLocations);
      this.filteredLocationMultiSelect.next(this.allFilteredLocations.slice());
      if (this.customSelectLocation) {
        this.customSelectLocation.setData(this.filteredLocations);
        if (this.customSelectLocation.dataArray.map((u) => u.id).length == 0) {
          if (this.currentLocation) {
            this.customSelectLocation.selectItem(this.currentLocation, { checked: true });
          }
        }
      }
    }
  }

  public getUsersAutocomplete(event) {
    this.getAutocompleteData(event, this.allFilteredUsers, this.filteredUsers,
      this.filteredUserMultiSelect, this.customSelectUser);
  }

  public getLocationsAutocomplete(event) {
    this.getAutocompleteData(event, this.allFilteredLocations, this.filteredLocations,
      this.filteredLocationMultiSelect, this.customSelectLocation);
  }

  public async onAdd(): Promise<void> {
    this.spinner = true;
    this.referenceLink = "";
    const data = {
      locationId: this.filteredLocationIds[0],
      ownerId: this.filterUserIds[0],
      adsSourceId: this.adsSource
    }

    const response = await this.socialMediaAdsTokenService.addToken(data);

    try {
      if (response.errorMessage) {
        console.error(response.errorMessage);
      } else if (response.tokenValue) {
        this.referenceLink = this.generateReferenceLink(response.tokenValue, this.adsSource);
      } else if (response.token) {
        this.referenceLink = this.generateReferenceLink(response.token, this.adsSource);
      }
    }
    catch (error) {
      console.error("An error occurred while adding the token: ", error);
    }
    finally {
      this.tokens = await this.socialMediaAdsTokenService.getTokens();
    }

    this.spinner = false;
  }

  public validation() {
    return (this.filteredLocationIds && this.filteredLocationIds.length > 0)
      && (this.filterUserIds && this.filterUserIds.length > 0)
      && !this.spinner
  }

  public getAdsSourceName(adsSourceId: SocialMediaAdsTypes): string {
    switch (adsSourceId) {
      case SocialMediaAdsTypes.Instagram:
        return SocialMediaAdsTypes[SocialMediaAdsTypes.Instagram];
      case SocialMediaAdsTypes.Facebook:
        return SocialMediaAdsTypes[SocialMediaAdsTypes.Facebook];      
      case SocialMediaAdsTypes.FacebookInstagram:
        return "Facebook/Instagram";
      default:
        return '';
    }
  }

  public trackByTokenId(index: number, token: any): string {
    return token.id;
  }

  public onAdsSourceChange(event: any): void {
    this.adsSource = event.value;
  }

  public getLocationFilterData() {
    this.filteredLocations = [];
    this.filteredLocations = this.filteredLocations.concat(this.allFilteredLocations);
    this.filteredLocationMultiSelect.next(this.allFilteredLocations.slice());

    if (this.customSelectLocation) {
      this.customSelectLocation.setData(this.filteredLocations);

      if (this.customSelectLocation.dataArray.map((u) => u.id).length == 0) {
        if (this.currentLocation) {
          this.customSelectLocation.selectItem(this.currentLocation, { checked: true });
        }
      }
    }
  }

  public copyReferenceLinkToClipboard(event: MouseEvent, value: string): void {
    event.preventDefault();
    this.clipboard.copy(value);
    this.snackBar.open('Link copied to clipboard!', 'Close', {
      duration: 2000,
    });
  }

  private initializeStore() {
    this.currentUser = this.userContextService.user.value;
    this.isMasterLeadAccount = this.currentUser && this.currentUser.role_id === RolesEnum.MasterLead;
  }

  private generateReferenceLink(tokenValue: string, adsSource: SocialMediaAdsTypes ): string {
    const baseUrl = environment.smAdsLeadsUrl;
    return `${baseUrl}${SocialMediaAdsTypes[adsSource].toLowerCase()}/?t=${tokenValue}`;
  }

  private async loadData(): Promise<void> {
    if (this.customSelectUser) {
      this.filterUserIds = this.customSelectUser.dataArray.map((u) => u.id);
    }

    if (!this.filterUserIds || this.filterUserIds.length == 0) {
      this.filterUserIds = new Array();
      if (this.currentUser) {
        this.filterUserIds.push(this.currentUser.id);
      }
    }

    if (this.customSelectLocation) {
      this.filteredLocationIds = this.customSelectLocation.dataArray.map((u) => u.id);
    }

    if (!this.filteredLocationIds || this.filteredLocationIds.length == 0) {
      this.filteredLocationIds = new Array();
      if (this.currentLocation) {
        this.filteredLocationIds.push(this.currentLocation.id);
      }
    }
  }

  private getAutocompleteData(event, allFilteredData, filteredData, filteredMultiSelect, customSelect) {
    let search = event.searchString;
    if (!search) {
      filteredMultiSelect.next(allFilteredData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filteredMultiSelect.next(
      allFilteredData.filter(loc => loc.name.toLowerCase().indexOf(search) > -1)
    );
    filteredData = [];
    filteredMultiSelect.subscribe(x => {
      for (let i = 0; i < x.length; i++) {
        if (filteredData.length == 0) {
          filteredData.push(x[0]);
        } else {
          if (!filteredData.includes(x[i], 0)) {
            filteredData.push(x[i]);
          }
        }
      }
    });
    customSelect.setData(filteredData);
  }
}