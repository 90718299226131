<mat-dialog-content>
  <div class="wrapper">
    <div [hidden]="!phoneRequired && !emailRequired">
      Please enter at least one {{ phoneRequired ? 'phone number' : '' }}{{ phoneRequired && emailRequired ? ' or ' : ''
      }}{{ emailRequired ? 'email' : '' }}.
    </div>
    <div class="phone-row" [hidden]="!phoneRequired && emailRequired">
      <div class="phone-label">Phone:</div>
      <div>
        <pd-field-intl-tel #fieldIntlTel [isNeedSaveCancelBtn]="false"></pd-field-intl-tel>
      </div>
    </div>
    <div class="email-row" [hidden]="phoneRequired && !emailRequired">
      <div class="email-label">Email:</div>
      <div>
        <pd-field-emails #fieldEmails [isNeedSaveCancelBtn]="false"></pd-field-emails>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="buttons">
    <button type="button" class="btn btn-danger cancel" (click)="confirm(false)">Cancel</button>
    <button type="button" class="btn btn-success save" [disabled]="!validationCheck()"
      (click)="confirm(true)">Save</button>
  </div>
</mat-dialog-actions>