import { ObjectUtil } from "../utils/object.util";
import { Role } from "./Role";

export class RolePropertyModel {
    public isTasksTab: boolean = false;
    public isUsersTab: boolean = false;
    public isAllowedExportLeadDate: boolean = false;
    public isAllowedHistoryOfLeads: boolean = false;
    public isRepoAccount: boolean = false;
    public isWhatsappChat: boolean = false;
    public isGlobalChat: boolean = false;
    public isAvatarEditor: boolean = false;
    public isInSmReport: boolean = false;
    public isNotInClothierReport: boolean = false;
    public isSeeOthersCms: boolean = false;
    public isMarketLeader: boolean = false;
    public leaderOf: number[] = [];
    public role: Role;

    constructor(data?: any) {
        ObjectUtil.extend(data, this);
    }
}